import React, { useState } from "react";
import ConfigFile from "./ParsePanelData";
import ConfigFileGenerator from "./GenerateConfigFile";
import Axios from "axios";
import { toast } from "react-toastify";

const MainContent = ({ panel, updatePanels }) => {
    const [configFileData, setConfigFileData] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const configFile = new ConfigFile();
            configFile.readFile(e.target.result);
            setConfigFileData(configFile.VT5000_CONFIG); // Store the parsed configuration data
            console.log("Parsed Config:", configFile.VT5000_CONFIG);

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/check_panel_exist`, {
                email: userEmail,
                panelName: configFile.VT5000_CONFIG.GeneralParameters.panelName,
                panel: configFile.VT5000_CONFIG,
            })
                .then((response) => {
                    if (response.data.success) {
                        toast.success("Panel added successfully!");
                        updatePanels(configFile.VT5000_CONFIG.GeneralParameters.panelName); // Update UI in PanelType.js
                    } else {
                        toast.error("Panel already exists!");
                    }
                })
                .catch((error) => {
                    console.error("Search panel failed!", error);
                    toast.error("Failed to check panel!");
                });
        };
        reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer


    };

    const handleExport = () => {
        if (!panel) {
            alert("Unknown issue, please refresh the page first!");
            return;
        }

        // Generate the configuration file
        const generator = new ConfigFileGenerator(panel.VT5000_CONFIG);
        const generatedBuffer = generator.generateConfigFile(); // Get the buffer for the file

        // Create a blob for the generated file and download it
        const blob = new Blob([generatedBuffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "ONELOOP.CFG";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ flex: "1", padding: "16px", color: "black" }}>
            <div style={{ textAlign: "center", fontSize: "24px" }}>Main Content</div>
            <input
                type="file"
                accept=".cfg"
                style={{ marginTop: "20px" }}
                onChange={handleFileUpload}
            />
            <button
                style={{ display: "block", margin: "20px auto" }}
                onClick={handleExport}
            >
                Export
            </button>
        </div>
    );
};

export default MainContent;
