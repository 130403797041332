import React, { useState, useEffect } from "react";
import UpIcon from "../../Assets/icons/Up.svg";
import DownIcon from "../../Assets/icons/Down.svg";
import Axios from "axios";

const HolidaysFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [holidayIndex, setHolidayIndex] = useState(1);
    const [month, setMonth] = useState(() => {
        if (panel?.VT5000_CONFIG?.Holidays) {
            if (panel?.VT5000_CONFIG?.Holidays.length === 12) {
                if (panel?.VT5000_CONFIG?.Holidays[0].programmed === 0) {
                    return 0;
                }
                else {
                    return panel?.VT5000_CONFIG?.Holidays[0].month;
                }
            }
        }
    });
    const [day, setDay] = useState(() => {
        if (panel?.VT5000_CONFIG?.Holidays) {
            if (panel?.VT5000_CONFIG?.Holidays.length === 12) {
                if (panel?.VT5000_CONFIG?.Holidays[0].programmed === 0) {
                    return 0;
                }
                else {
                    return panel?.VT5000_CONFIG?.Holidays[0].day;
                }
            }
        }
    });
    const [isHolidayDeleted, setIsHolidayDeleted] = useState(false);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const incrementValue = (setter, min, max) => {
        setter(prev => {
            const newIndex = prev >= max ? min : prev + 1;
            if (setter === setHolidayIndex) {
                setDay(panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.programmed === 0 ? 0 : panel.VT5000_CONFIG.Holidays[newIndex - 1].day || 0);
                setMonth(panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.programmed === 0 ? 0 : panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.month || 0);
            }
            setIsHolidayDeleted(false);
            return newIndex;
        });
    };

    const decrementValue = (setter, min, max) => {
        setter(prev => {
            const newIndex = prev <= min ? max : prev - 1;
            if (setter === setHolidayIndex) {
                setDay(panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.programmed === 0 ? 0 : panel.VT5000_CONFIG.Holidays[newIndex - 1].day || 0);
                setMonth(panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.programmed === 0 ? 0 : panel?.VT5000_CONFIG?.Holidays[newIndex - 1]?.month || 0);
            }
            setIsHolidayDeleted(false);
            return newIndex;
        });
    };

    const handleDelete = () => {
        setIsHolidayDeleted(true);
        setDay(0);
        setMonth(0);
    };

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.Holidays && panel.VT5000_CONFIG.GeneralParameters) {
            if (isHolidayDeleted) {
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].programmed = 0;
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].month = 0;
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].day = 0;
            }
            else {
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].programmed = 1;
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].month = month;
                panel.VT5000_CONFIG.Holidays[holidayIndex - 1].day = day;
            }

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_holidays`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                holidays: panel.VT5000_CONFIG.Holidays
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Holidays saving updated successfully");
                        setCurrentFrame("clock");
                    } else {
                        console.error("Failed to update holidays");
                    }
                })
                .catch((error) => {
                    console.error("Error updating holidays:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Bank Holidays</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                {[[holidayIndex, setHolidayIndex, 1, 12, "Festivity n.:"], [day, setDay, 0, 31, "Day:"]].map(([value, setter, min, max, label], index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "5px" }}>
                        <span style={{ fontSize: "30px" }}>{label}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button onClick={() => incrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={UpIcon} alt="Up" style={{ width: "40px", height: "40px" }} />
                            </button>
                            <input type="number" value={value} readOnly style={{ fontSize: "40px", textAlign: "center", width: "100px", height: "100px", border: "2px solid black", borderRadius: "5px" }} />
                            <button onClick={() => decrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={DownIcon} alt="Down" style={{ width: "40px", height: "40px" }} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "80%", justifyContent: "space-between", marginTop: "3%" }}>
                <span style={{ fontSize: "30px", marginLeft: "61%" }}>Month:</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                <button style={{ fontSize: "40px", background: "#FFF", color: "black", borderRadius: "5px", padding: "10px", width: "250px", height: "100px" }} onClick={handleDelete}>Delete</button>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button onClick={() => incrementValue(setMonth, 0, 12)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                        <img src={UpIcon} alt="Up" style={{ width: "40px", height: "40px" }} />
                    </button>
                    <input type="number" value={month} readOnly style={{ fontSize: "40px", textAlign: "center", width: "100px", height: "100px", border: "2px solid black", borderRadius: "5px" }} />
                    <button onClick={() => decrementValue(setMonth, 0, 12)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                        <img src={DownIcon} alt="Down" style={{ width: "30px", height: "30px" }} />
                    </button>
                </div>
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("clock")}>Back</button>
            </div>
        </div>
    );
};

export default HolidaysFrame;
