import React, { useState, useEffect } from "react";
import { Dropdown } from "@fluentui/react";
import Axios from "axios";
import { Icon } from "@fluentui/react/lib/Icon";

const SupervisionFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [baudRate, setBaudRate] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.rs232BaudRate || 0);
    const [physicalAddress, setPhysicalAddress] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.rs232Address || 0);
    const [logicalAddress, setLogicalAddress] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.rs232Dummy || 0);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleSave = () => {
        console.log(panel.VT5000_CONFIG.GeneralParameters);
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            panel.VT5000_CONFIG.GeneralParameters.rs232BaudRate = baudRate;
            panel.VT5000_CONFIG.GeneralParameters.rs232Address = physicalAddress;
            panel.VT5000_CONFIG.GeneralParameters.rs232Dummy = logicalAddress;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                generalparameters: panel.VT5000_CONFIG.GeneralParameters
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Loop settings updated successfully");
                    } else {
                        console.error("Failed to update loop settings");
                    }
                })
                .catch((error) => {
                    console.error("Error updating loop settings:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Supervision</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", width: "80%" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Baud Rate</span>
                    <Dropdown
                        placeholder="Select Baud Rate"
                        options={[9600, 19200, 38400, 57600].map(rate => ({ key: rate, text: rate.toString() }))}
                        selectedKey={baudRate}
                        onChange={(e, option) => setBaudRate(option.key)}
                        onRenderCaretDown={() => <Icon iconName="ChevronDown" styles={{ root: { fontSize: 20 } }} />} // Custom caret down icon
                        styles={{ dropdown: { width: 150 }, callout: { maxHeight: 200 } }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Physical Address</span>
                    <Dropdown
                        placeholder="Select Physical Address"
                        options={Array.from({ length: 240 }, (_, i) => ({ key: i + 1, text: (i + 1).toString() }))}
                        selectedKey={physicalAddress}
                        onChange={(e, option) => setPhysicalAddress(option.key)}
                        onRenderCaretDown={() => <Icon iconName="ChevronDown" styles={{ root: { fontSize: 20 } }} />} // Custom caret down icon
                        styles={{ dropdown: { width: 150 }, callout: { maxHeight: 200 } }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Logical Address</span>
                    <Dropdown
                        placeholder="Select Logical Address"
                        options={Array.from({ length: 240 }, (_, i) => ({ key: i + 1, text: (i + 1).toString() }))}
                        selectedKey={logicalAddress}
                        onChange={(e, option) => setLogicalAddress(option.key)}
                        onRenderCaretDown={() => <Icon iconName="ChevronDown" styles={{ root: { fontSize: 20 } }} />} // Custom caret down icon
                        styles={{ dropdown: { width: 150 }, callout: { maxHeight: 200 } }}
                    />
                </div>
            </div>

            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("general")}>Back</button>
            </div>
        </div>
    );
};

export default SupervisionFrame;
