import React, { useState, useEffect } from "react";

const AssistanceFrame = ({ setCurrentFrame }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [masterVersion, setMasterVersion] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let formattedTime = now.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).replace(/,/g, ""); // Remove comma between date and time

      formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/version.json`) // Fetch from public folder
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch version.json");
        }
        return response.json();
      })
      .then((data) => setMasterVersion(data.masterVersion))
      .catch((error) => {
        console.error("Error loading version:", error);
        setMasterVersion("Unknown");
      });
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Assistance</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ fontSize: "35px", marginBottom: "10px", textAlign: "left", width: "98%", paddingLeft: "10px" }}>
        Assistance: Unit 11, LIU-1, Dubai Silicon Oasis, Dubai, UAE
      </div>
      <div style={{ fontSize: "35px", marginBottom: "10px", textAlign: "left", width: "98%", paddingLeft: "5%" }}>
        Etisalat
      </div>
      <div style={{ fontSize: "35px", marginBottom: "10px", textAlign: "left", width: "98%", paddingLeft: "5%" }}>
        Master Version: {masterVersion}
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("programming")}>Back</button>
      </div>
    </div>
  );
};

export default AssistanceFrame;
