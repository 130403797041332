// const fs = require('fs');
import { Buffer } from 'buffer';


// VT5000_CONFIG equivalent in JavaScript to manage constants and initialization
const VT5000_CONFIG = {
    MAX_OUTPUT_ZONES: 256,
    MAX_OUT_PER_OUT_ZONE: 8,
    ZONE_NUMBER: 192,
    MAX_OUT_PER_AREA: 8,
    LZONE_NUM: 192,
    MAX_OZONE_PER_FUNC: 4,
    MAX_C1_LEVEL: 4,
    MAX_C2_LEVEL: 4,
    MAX_LOGIC_FUNC: 192,
    LOOP_NUMBER_MAX: 9,
    MAX_ARGUS_DEVICES: 240,
    MAX_VT_NET: 64,
    TEXT_LENGTH: 21,
    PANEL_NAME_LENGTH: 31,
    HOLIDAYS_NB: 12,
    TIME_INTERVALS: 255,
    WEEKLY_TRENDS_NB: 255,
    ACTIVATION_TYPE: {
        ALARM: 0,
        PRE_ALARM: 1,
        FAULT: 2,
        DISABLEMENT: 3,
        TECHNICAL: 4,
        ALL: 5
    },
    LOGIC_OPERATOR: {
        NONE: 0,
        AND_OPERATOR: 1,
        OR_OPERATOR: 2,
        XOR_OPERATOR: 3,
        NOT_OPERATOR: 4,
        FIRST_OPERATOR: 5,
        END_OPERATOR: 6
    }
};

// DeviceDescriptor equivalent in JavaScript
class DeviceDescriptor {
    constructor() {
        this.loop = 0;
        this.address = 0;
        this.active = 0;
    }
}

// OutputZone equivalent in JavaScript
class OutputZone {
    constructor() {
        this.silenceable = 0; // Silenceable & 01, Activation Type
        this.programmed = 0;
        this.devices = Array(VT5000_CONFIG.MAX_OUT_PER_OUT_ZONE).fill(null).map(() => new DeviceDescriptor());
    }
}

// OutZoneDescriptor equivalent in JavaScript
class OutZoneDescriptor {
    constructor() {
        this.loop = 0;
        this.address = 0;
        this.silenceable = 0;
        this.active = 0;
    }
}

// ZoneDescriptor equivalent in JavaScript
class ZoneDescriptor {
    constructor() {
        this.name = "";
        this.alarmCoincidence = 0;
        this.preAlarmCoincidence = 0;
        this.faultCoincidence = 0;
        this.disablementCoincidence = 0;
        this.technicalCoincidence = 0;
        this.weeklyTrendDisablement = 0;

        this.alarm = 0;
        this.preAlarm = 0;
        this.fault = 0;
        this.disablement = 0;
        this.technical = 0;
        this.allSystem = 0;
        this.loop = 0;

        this.programmed = 0;

        this.outputZone = Array(VT5000_CONFIG.MAX_OUT_PER_OUT_ZONE).fill(null).map(() => new OutZoneDescriptor());
    }
}

// GP_COMM equivalent in JavaScript
class GpComm {
    constructor() {
        this.password1 = 0;
        this.password2 = 0;
        this.password3 = 0;
        this.psuDemoMode = 0;

        this.vtnetPanelAddress = 0;
        this.vtnetNetworkPanelNb = 0;

        this.vtnetAlarmVisible = 0;
        this.vtnetFaultVisible = 0;
        this.vtnetResetEnable = 0;
        this.vtnetEvacEnable = 0;

        this.loopCardNb = 0;

        this.loop2Closed = 0;
        this.loop3Closed = 0;
        this.loop4Closed = 0;
        this.loop5Closed = 0;
        this.loop6Closed = 0;
        this.loop7Closed = 0;
        this.loop8Closed = 0;
        this.loop9Closed = 0;

        this.silenceDuration = 0; // 2 bytes

        this.rs232BaudRate = 0; // 4 bytes
        this.panelLanguage = 0; // 0 = Italiano, 1 = English, 2 = Turco, 3 = ....

        this.panelName = "";
        this.assistanceName = "";

        this.panelColour = 0;

        this.rs232Address = 0;
        this.rs232Dummy = 0; // useless

        this.panelLedBacklightColour = 0;

        this.buzzerDisabled = 0;
        this.fireRelayDisablement = 0;
        this.devicePollingLed = 0; // on or off
        this.loop1LedBlinkColour = 0; // 1 = Red, 0 = Green
        this.earthFaultCheck = 0; // 0: disabled, 1: enabled
        this.loop1Closed = 0; // Loop1: loop closed=1, loop open=0
    }
}

// CODICI equivalent in JavaScript
class Codici {
    constructor() {
        this.engineer = 0;
        this.op1 = 0;
        this.op2 = 0;
        this.op3 = 0;
    }
}

// ORALEGALE equivalent in JavaScript
class Oralegale {
    constructor() {
        this.savingTime = {
            startDay: 0,
            endDay: 0,
            startMonth: 0,
            endMonth: 0
        };
        this.notProgrammed = 0; // noOraLegale 1 if not programmed
        this.mode = 0; // auto=0, manual=1
    }
}

// FESTIVITA equivalent in JavaScript
class Festivita {
    constructor() {
        this.day = 0;
        this.month = 0;
        this.programmed = 0;
    }
}

// TIME_INTERVAL equivalent in JavaScript
class TimeInterval {
    constructor() {
        this.hourStart = 0;
        this.hourEnd = 0;
        this.minuteStart = 0;
        this.minuteEnd = 0;
        this.programmed = 0;
    }
}

// FASCIA_GIORNALIERA equivalent in JavaScript
class FasciaGiornaliera {
    constructor() {
        this.fo = [new TimeInterval(), new TimeInterval()];
    }
}

// FASCIA_SETTIMANALE equivalent in JavaScript
class FasciaSettimanale {
    constructor() {
        this.days = Array(8).fill(0); // time interval linked with days+holidays
        this.programmed = 0;
    }
}

// C1_TYPE_T equivalent in JavaScript
class C1TypeT {
    constructor() {
        this.c1Operat = 0;
        this.c2Operand = Array(VT5000_CONFIG.MAX_C2_LEVEL).fill(0);
        this.c2Operator = Array(VT5000_CONFIG.MAX_C2_LEVEL).fill(0);
    }
}

// F_LOGIC_PR_T equivalent in JavaScript
class FLogicPrT {
    constructor() {
        this.programmed = 0; // Logic programmed
        this.fLogicAss = 0; // flag function associated with another flogic zone
        this.c1Level = Array(VT5000_CONFIG.MAX_C1_LEVEL).fill(null).map(() => new C1TypeT());
        this.outputZones = Array(VT5000_CONFIG.MAX_OZONE_PER_FUNC).fill(0);
        this.delay = 0;
        this.duration = 0;
    }
}

// INPUT_DESCRIPTOR equivalent in JavaScript
class InputDescriptor {
    constructor() {
        this.deviceType = 0; // Sensor = 0 / Module = 1
        this.sounderBase = 0;
        this.name = "";
        this.alarmVerification = 0; // alarm verification enable
        this.alarmVerificationDelay = 0; // 7 bits delay in seconds
        this.isGas = 0;
        this.isSounder = 0;
        this.lastTipo = 0; // device type (0 = Optical, 1 = heat, 2 = Button, 3 = Input, 4 = Out, 5 = undefined for the modules see .tid)
        this.isMultiSensor = 0;
        this.isCallPoint = 0;
        this.isRadio = 0;
        this.isFlasher = 0;
        this.preAllTemp = 0;
        this.isRepeater = 0;
        this.prealarm = 0;
        this.dayNight = 0;
        this.sensSecDis = 0;
        this.technical = 0; // Tecnologico
        this.detectorDaySensitivity = 0;
        this.detectorNightSensitivity = 0;
        this.resSensor = 0;
        this.evacuationEnabled = 0;
        this.programmed = 0;
        this.pNP = 0;
        this.secDisEn = 0;
        this.intendedDisChannel = 0;
        this.zone = 0; // Zone associated with the device
        this.technicalFault = 0;
        this.technicalRestore = 0;
        this.detectorMultiSenseWeekCalendar = 0;
        this.detectorSensitivityWeekCalendar = 0;
        this.tone = 0;
        this.volume = 0;
    }
}

// FIRE_NET_PROG_OLY equivalent in JavaScript
class FireNetProgOly {
    constructor() {
        this.panelType = 0; // VT5000, Vita
        this.loopNb = 0;
    }
}

class ConfigFile {
    constructor() {
        this.VT5000_CONFIG = {
            OutputZone: Array(VT5000_CONFIG.MAX_OUTPUT_ZONES)
                .fill(null)
                .map(() => new OutputZone()),
            Zone_Table: Array(VT5000_CONFIG.LZONE_NUM)
                .fill(null)
                .map(() => new ZoneDescriptor()),
            GeneralParameters: new GpComm(),
            Codes: new Codici(),
            Daylightsaving: new Oralegale(),
            Holidays: Array(VT5000_CONFIG.HOLIDAYS_NB)
                .fill(null)
                .map(() => new Festivita()),
            TimeIntervals: Array(VT5000_CONFIG.TIME_INTERVALS)
                .fill(null)
                .map(() => new FasciaGiornaliera()),
            WeeklyTrends: Array(VT5000_CONFIG.WEEKLY_TRENDS_NB)
                .fill(null)
                .map(() => new FasciaSettimanale()),
            FlogicPr: Array(VT5000_CONFIG.MAX_LOGIC_FUNC)
                .fill(null)
                .map(() => new FLogicPrT()),
            ArgusTablePr: Array(VT5000_CONFIG.LOOP_NUMBER_MAX)
                .fill(null)
                .map(() => Array(VT5000_CONFIG.MAX_ARGUS_DEVICES)
                    .fill(null)
                    .map(() => new InputDescriptor())),
            VTNet: Array(VT5000_CONFIG.MAX_VT_NET)
                .fill(null)
                .map(() => new FireNetProgOly()),
        };
    }

    parseBuffer(buffer) {
        let offset = 0;
        let valueByte = 0;
        let valueInt16 = 0;
        let valueInt32 = 0;

        for (let i = 0; i < VT5000_CONFIG.MAX_OUTPUT_ZONES; i++) {
            valueInt16 = buffer.readUInt16LE(offset);
            offset += 2;

            this.VT5000_CONFIG.OutputZone[i].silenceable = valueInt16 & 0x01;
            this.VT5000_CONFIG.OutputZone[i].programmed = (valueInt16 & 0x40) > 0 ? 1 : 0;

            for (let a = 0; a < VT5000_CONFIG.MAX_OUT_PER_OUT_ZONE; a++) {
                let deviceValue = buffer.readUInt16LE(offset);
                offset += 2;

                this.VT5000_CONFIG.OutputZone[i].devices[a].loop = deviceValue & 0x1f;
                this.VT5000_CONFIG.OutputZone[i].devices[a].address = (deviceValue >> 5) & 0xff;
                this.VT5000_CONFIG.OutputZone[i].devices[a].active = (deviceValue >> 1) & 0x01;
            }
        }

        // Parse Zone_Table
        for (let i = 0; i < VT5000_CONFIG.LZONE_NUM; i++) {
            this.VT5000_CONFIG.Zone_Table[i].name = buffer
                .toString('utf8', offset, offset + VT5000_CONFIG.TEXT_LENGTH)
                .trim();
            offset += VT5000_CONFIG.TEXT_LENGTH;

            this.VT5000_CONFIG.Zone_Table[i].alarmCoincidence = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Zone_Table[i].preAlarmCoincidence = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Zone_Table[i].faultCoincidence = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Zone_Table[i].disablementCoincidence = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Zone_Table[i].technicalCoincidence = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Zone_Table[i].weeklyTrendDisablement = buffer.readUInt8(offset++);
            offset++; // Skipping node

            valueInt16 = buffer.readUInt16LE(offset);
            offset += 2;

            this.VT5000_CONFIG.Zone_Table[i].alarm = (valueInt16 >> 2) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].preAlarm = (valueInt16 >> 3) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].fault = (valueInt16 >> 4) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].disablement = (valueInt16 >> 5) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].technical = (valueInt16 >> 6) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].allSystem = (valueInt16 >> 7) & 0x01;
            this.VT5000_CONFIG.Zone_Table[i].loop = (valueInt16 >> 8) & 0x1f;
            this.VT5000_CONFIG.Zone_Table[i].programmed = (valueInt16 >> 14) & 0x01;

            for (let a = 0; a < VT5000_CONFIG.MAX_OUT_PER_AREA; a++) {
                let outputValue = buffer.readUInt16LE(offset);
                offset += 2;

                this.VT5000_CONFIG.Zone_Table[i].outputZone[a].loop = outputValue & 0x1f;
                this.VT5000_CONFIG.Zone_Table[i].outputZone[a].address = (outputValue >> 5) & 0xff;
                this.VT5000_CONFIG.Zone_Table[i].outputZone[a].silenceable = (outputValue >> 13) & 0x01;
                this.VT5000_CONFIG.Zone_Table[i].outputZone[a].active = (outputValue >> 14) & 0x01;
            }
        }

        valueInt32 = buffer.readUInt32LE(offset);
        offset += 4;

        this.VT5000_CONFIG.GeneralParameters.password1 = (valueInt32 >> 22) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.password2 = (valueInt32 >> 23) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.password3 = (valueInt32 >> 24) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.psuDemoMode = (valueInt32 >> 25) & 0x0f;

        this.VT5000_CONFIG.GeneralParameters.vtnetPanelAddress = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.GeneralParameters.vtnetNetworkPanelNb = buffer.readUInt8(offset++);
        offset++;

        valueByte = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.GeneralParameters.vtnetAlarmVisible = (valueByte) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.vtnetFaultVisible = (valueByte >> 1) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.vtnetResetEnable = (valueByte >> 2) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.vtnetEvacEnable = (valueByte >> 3) & 0x01;

        this.VT5000_CONFIG.GeneralParameters.loopCardNb = buffer.readUInt8(offset++);

        valueByte = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.GeneralParameters.loop2Closed = (valueByte) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop3Closed = (valueByte >> 1) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop4Closed = (valueByte >> 2) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop5Closed = (valueByte >> 3) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop6Closed = (valueByte >> 4) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop7Closed = (valueByte >> 5) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop8Closed = (valueByte >> 6) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop9Closed = (valueByte >> 7) & 0x01;

        this.VT5000_CONFIG.GeneralParameters.silenceDuration = buffer.readUInt16LE(offset);
        offset += 2;

        this.VT5000_CONFIG.GeneralParameters.rs232BaudRate = buffer.readUInt32LE(offset);
        offset += 4;
        this.VT5000_CONFIG.GeneralParameters.panelLanguage = buffer.readUInt8(offset++);

        this.VT5000_CONFIG.GeneralParameters.panelName = buffer.toString('utf8', offset, offset + VT5000_CONFIG.PANEL_NAME_LENGTH).trim();
        offset += VT5000_CONFIG.PANEL_NAME_LENGTH;
        this.VT5000_CONFIG.GeneralParameters.assistanceName = buffer.toString('utf8', offset, offset + VT5000_CONFIG.PANEL_NAME_LENGTH).trim();
        offset += VT5000_CONFIG.PANEL_NAME_LENGTH;
        this.VT5000_CONFIG.GeneralParameters.panelColour = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.GeneralParameters.rs232Address = buffer.readUInt16LE(offset);
        offset += 2;
        this.VT5000_CONFIG.GeneralParameters.rs232Dummy = buffer.readUInt16LE(offset);
        offset += 2;
        this.VT5000_CONFIG.GeneralParameters.panelLedBacklightColour = buffer.readUInt8(offset++);
        offset += 3;

        valueInt32 = buffer.readUInt32LE(offset);
        offset += 4;

        this.VT5000_CONFIG.GeneralParameters.buzzerDisabled = (valueInt32 >> 3) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.fireRelayDisablement = (valueInt32 >> 7) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.devicePollingLed = (valueInt32 >> 9) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop1LedBlinkColour = (valueInt32 >> 14) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.earthFaultCheck = (valueInt32 >> 15) & 0x01;
        this.VT5000_CONFIG.GeneralParameters.loop1Closed = (valueInt32 >> 16) & 0x01;
        offset += 4;

        this.VT5000_CONFIG.Codes.engineer = buffer.readUInt32LE(offset);
        offset += 4;
        this.VT5000_CONFIG.Codes.op1 = buffer.readUInt32LE(offset);
        offset += 4;
        this.VT5000_CONFIG.Codes.op2 = buffer.readUInt32LE(offset);
        offset += 4;
        this.VT5000_CONFIG.Codes.op3 = buffer.readUInt32LE(offset);
        offset += 4;

        this.VT5000_CONFIG.Daylightsaving.savingTime.startDay = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.Daylightsaving.savingTime.startMonth = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.Daylightsaving.savingTime.endDay = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.Daylightsaving.savingTime.endMonth = buffer.readUInt8(offset++);

        valueByte = buffer.readUInt8(offset++);
        this.VT5000_CONFIG.Daylightsaving.mode = (valueByte) && 0x01;
        this.VT5000_CONFIG.Daylightsaving.notProgrammed = (valueByte >> 2) & 0x01;

        for (let i = 0; i < VT5000_CONFIG.HOLIDAYS_NB; i++) {
            this.VT5000_CONFIG.Holidays[i].day = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Holidays[i].month = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.Holidays[i].programmed = buffer.readUInt8(offset++) & 0x01;
        }

        for (let i = 0; i < VT5000_CONFIG.TIME_INTERVALS; i++) {
            this.VT5000_CONFIG.TimeIntervals[i].fo[0].hourStart = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[0].minuteStart = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[0].hourEnd = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[0].minuteEnd = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[0].programmed = buffer.readUInt8(offset++) & 0x01;

            this.VT5000_CONFIG.TimeIntervals[i].fo[1].hourStart = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[1].minuteStart = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[1].hourEnd = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[1].minuteEnd = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.TimeIntervals[i].fo[1].programmed = buffer.readUInt8(offset++) & 0x01;
        }

        for (let i = 0; i < VT5000_CONFIG.WEEKLY_TRENDS_NB; i++) {
            this.VT5000_CONFIG.WeeklyTrends[i].days[0] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[1] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[2] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[3] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[4] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[5] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[6] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].days[7] = buffer.readUInt8(offset++);
            this.VT5000_CONFIG.WeeklyTrends[i].programmed = buffer.readUInt8(offset++) & 0x01;
        }

        for (let i = 0; i < VT5000_CONFIG.MAX_LOGIC_FUNC; i++) {
            valueInt32 = buffer.readUInt32LE(offset);
            offset += 4;

            this.VT5000_CONFIG.FlogicPr[i].programmed = valueInt32 & 0x01;
            this.VT5000_CONFIG.FlogicPr[i].fLogicAss = (valueInt32 >> 1) & 0x01;

            for (let a = 0; a < VT5000_CONFIG.MAX_C1_LEVEL; a++) {
                this.VT5000_CONFIG.FlogicPr[i].c1Level[a].c1Operat = buffer.readUInt32LE(offset) & 0xFF;
                offset += 4;
                for (let b = 0; b < VT5000_CONFIG.MAX_C2_LEVEL; b++) {
                    this.VT5000_CONFIG.FlogicPr[i].c1Level[a].c2Operand[b] = buffer.readUInt32LE(offset);
                    offset += 4;
                    this.VT5000_CONFIG.FlogicPr[i].c1Level[a].c2Operand[b] = buffer.readUInt32LE(offset) & 0xFF;
                    offset += 4;
                }
            }

            for (let a = 0; a < VT5000_CONFIG.MAX_C1_LEVEL; a++) {
                this.VT5000_CONFIG.FlogicPr[i].outputZones[a] = buffer.readUInt8(offset++);
            }

            this.VT5000_CONFIG.FlogicPr[1].delay = buffer.readUInt16LE(offset);
            offset += 2;
            this.VT5000_CONFIG.FlogicPr[1].duration = buffer.readUInt16LE(offset);
            offset += 2;
        }

        for (let i = 0; i < VT5000_CONFIG.LOOP_NUMBER_MAX; i++) {
            for (let j = 0; j < VT5000_CONFIG.MAX_ARGUS_DEVICES; j++) {
                valueInt16 = buffer.readUInt16LE(offset);
                offset += 2;
                this.VT5000_CONFIG.ArgusTablePr[i][j].deviceType = valueInt16 & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].sounderBase = (valueInt16 >> 1) & 0x01;

                this.VT5000_CONFIG.ArgusTablePr[i][j].name = buffer
                    .toString('utf8', offset, offset + VT5000_CONFIG.TEXT_LENGTH)
                    .trim();
                offset += VT5000_CONFIG.TEXT_LENGTH;

                valueInt32 = buffer.readUInt32LE(offset);
                offset += 4;
                this.VT5000_CONFIG.ArgusTablePr[i][j].alarmVerification = (valueInt32) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].alarmVerificationDelay = (valueInt32 >> 1) & 0x7f;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isGas = (valueInt32 >> 8) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isSounder = (valueInt32 >> 9) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].lastTipo = (valueInt32 >> 10) & 0x07;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isMultiSensor = (valueInt32 >> 13) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isCallPoint = (valueInt32 >> 14) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isRadio = (valueInt32 >> 15) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isFlasher = (valueInt32 >> 16) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].preAllTemp = (valueInt32 >> 17) & 0xff;
                this.VT5000_CONFIG.ArgusTablePr[i][j].isRepeater = (valueInt32 >> 25) & 0x01;

                valueInt16 = buffer.readUInt16LE(offset);
                offset += 2;
                this.VT5000_CONFIG.ArgusTablePr[i][j].prealarm = valueInt16 & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].dayNight = (valueInt16 >> 1) & 0x03;
                this.VT5000_CONFIG.ArgusTablePr[i][j].sensSecDis = (valueInt16 >> 3) & 0x07;
                this.VT5000_CONFIG.ArgusTablePr[i][j].technical = (valueInt16 >> 6) & 0x07;
                this.VT5000_CONFIG.ArgusTablePr[i][j].detectorDaySensitivity = (valueInt16 >> 7) & 0x03;
                this.VT5000_CONFIG.ArgusTablePr[i][j].detectorNightSensitivity = (valueInt16 >> 9) & 0x03;
                this.VT5000_CONFIG.ArgusTablePr[i][j].resSensor = (valueInt16 >> 11) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].evacuationEnabled = (valueInt16 >> 12) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].programmed = (valueInt16 >> 13) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].pNP = (valueInt16 >> 14) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].secDisEn = (valueInt16 >> 15) & 0x01;

                this.VT5000_CONFIG.ArgusTablePr[i][j].intendedDisChannel = buffer.readUInt8(offset++);

                this.VT5000_CONFIG.ArgusTablePr[i][j].zone = buffer.readUInt8(offset++);
                offset += 3;

                valueByte = buffer.readUInt8(offset++);
                this.VT5000_CONFIG.ArgusTablePr[i][j].technicalFault = (valueByte >> 2) & 0x01;
                this.VT5000_CONFIG.ArgusTablePr[i][j].technicalRestore = (valueByte >> 3) & 0x01;

                this.VT5000_CONFIG.ArgusTablePr[i][j].detectorMultiSenseWeekCalendar = buffer.readUInt8(offset++);
                this.VT5000_CONFIG.ArgusTablePr[i][j].detectorSensitivityWeekCalendar = buffer.readUInt8(offset++);
                
                valueByte = buffer.readUInt8(offset++);
                this.VT5000_CONFIG.ArgusTablePr[i][j].tone = valueByte & 0x1f;
                this.VT5000_CONFIG.ArgusTablePr[i][j].volume = (valueByte >> 5) & 0x07;
            }
        }

        for (let i = 0; i < VT5000_CONFIG.MAX_VT_NET; i++) {
            valueInt32 = buffer.readUInt32LE(offset);
            offset += 4;
            this.VT5000_CONFIG.VTNet[i].panelType = valueInt32 & 0x03;
            this.VT5000_CONFIG.VTNet[i].loopNb = (valueInt32 >> 2) & 0x1f;
        }
        offset += 20;
        console.log('Configuration file read successfully.');
        console.log(this.VT5000_CONFIG);
    }

    readFile(data) {
        console.log("data:");
        console.log(data);
        const buffer = Buffer.from(data); // Ensure data is treated as a buffer
        console.log("buffer:");
        console.log(buffer);
        this.parseBuffer(buffer); // Delegate to parseBuffer
    }

}

export default ConfigFile;
export { VT5000_CONFIG };

