import React, { useState, useEffect } from "react";
import Add from "../../Assets/icons/Add.svg";
import Modify from "../../Assets/icons/Modify.svg";
import Delete from "../../Assets/icons/Delete.svg";

const TimeSlotFrame = ({ setCurrentFrame }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
      const updateTime = () => {
        const now = new Date();
        let formattedTime = now.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }).replace(/,/g, ""); // Remove comma between date and time
  
        formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
        setCurrentTime(formattedTime);
      };
  
      updateTime();
      const interval = setInterval(updateTime, 1000);
      return () => clearInterval(interval);
    }, []);


  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Time Slot</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1px", width: "98%", height: "36%", textAlign: "center", margin: "8% 0 0 0" }}>
        {[{ icon: Add, text: "Add", onClick: () => setCurrentFrame("addTimeSlot") },
        { icon: Modify, text: "Modify", onClick: () => setCurrentFrame("modifyTimeSlot") },
        { icon: Delete, text: "Delete", onClick: () => setCurrentFrame("deleteTimeSlot") },].map((btn, index) => (
          <button key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", fontSize: "30px", width: "100%", background: btn.disabled ? "#ccc" : "none", borderRadius: "5px", cursor: btn.disabled ? "not-allowed" : "pointer", opacity: btn.disabled ? 0.5 : 1, }}
            onClick={btn.disabled ? undefined : btn.onClick}
            disabled={btn.disabled}
          >
            <img src={btn.icon} alt={btn.text} style={{ width: "70px", height: "70px", marginBottom: "10px" }} />
            {btn.text}
          </button>
        ))}
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
          <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("clock")}>Back</button>
        </div>
      </div>
    );
};

export default TimeSlotFrame;
