import React, { useState, useEffect } from "react";
import "../Styles/DevicesContent.css";
import {
  Dropdown,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  Panel,
  PanelType,
  Toggle,
  SpinButton
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Axios from "axios";


const DevicesContent = ({ panel }) => {
  const [selectedLoop, setSelectedLoop] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null); // Store selected device index
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [isWeeklyTrendDialogOpen, setIsWeeklyTrendDialogOpen] = useState(false);
  const [isDeactivateWeeklyTrendDialogOpen, setIsDeactivateWeeklyTrendDialogOpen] = useState(false);
  const [selectWeeklyTrendBlock, setSelectWeeklyTrendBlock] = useState(null);
  const [selectDeactivateWeeklyTrendBlock, setSelectDeactivateWeeklyTrendBlock] = useState(null);
  const [name, setName] = useState("");
  const [tempName, setTempName] = useState("");
  const [isRadio, setIsRadio] = useState(false);
  const [tempIsRadio, setTempIsRadio] = useState(false);
  // Reset Detectors
  const [evacuationEnabled, setEvacuationEnabled] = useState(false);
  const [tempEvacuationEnabled, setTempEvacuationEnabled] = useState(false);
  const [volume, setVolume] = useState(0);
  const [tempVolume, setTempVolume] = useState(0);
  const [tone, setTone] = useState(0);
  const [tempTone, setTempTone] = useState(0);
  const [alarmVerification, setAlarmVerification] = useState(false);
  const [tempAlarmVerification, setTempAlarmVerification] = useState(false);
  const [alarmVerificationDelay, setAlarmVerificationDelay] = useState(0);
  const [tempAlarmVerificationDelay, setTempAlarmVerificationDelay] = useState(0);
  const [technical, setTechnical] = useState(false);
  const [tempTechnical, setTempTechnical] = useState(false);
  const [prealarm, setPrealarm] = useState(false);
  const [tempPrealarm, setTempPrealarm] = useState(false);
  const [preAllTemp, setPreAllTemp] = useState(0);
  const [tempPreAllTemp, setTempPreAllTemp] = useState(0);
  const [sounderBase, setSounderBase] = useState(false);
  const [tempSounderBase, setTempSounderBase] = useState(false);
  const [dayNight, setDayNight] = useState(0);
  const [tempDayNight, setTempDayNight] = useState(0);
  const [detectorDaySensitivity, setDetectorDaySensitivity] = useState(0);
  const [tempDetectorDaySensitivity, setTempDetectorDaySensitivity] = useState(0);
  const [intendedDisChannel, setIntendedDisChannel] = useState(0);
  const [tempIntendedDisChannel, setTempIntendedDisChannel] = useState(0);
  const [detectorSensitivityWeekCalendar, setDetectorSensitivityWeekCalendar] = useState(0);
  const [tempDetectorSensitivityWeekCalendar, setTempDetectorSensitivityWeekCalendar] = useState(0);
  const [sensSecDis, setSensSecDis] = useState(0);
  const [tempSensSecDisDay, setTempSensSecDisDay] = useState(0);
  const [tempSensSecDisNight, setTempSensSecDisNight] = useState(0);
  const [detectorMultiSenseWeekCalendar, setDetectorMultiSenseWeekCalendar] = useState(0);
  const [tempDetectorMultiSenseWeekCalendar, setTempDetectorMultiSenseWeekCalendar] = useState(0);

  useEffect(() => {
    if (selectedDevice !== null && panel) {
      const i = selectedLoop;
      const j = selectedDevice;
      const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];

      if (device) {
        setName(device.name);
        setTempName(device.name);
        setIsRadio(device.isRadio === 1);
        setTempIsRadio(device.isRadio === 1);
        setEvacuationEnabled(device.evacuationEnabled === 1);
        setTempEvacuationEnabled(device.evacuationEnabled === 1);
        setVolume(device.volume);
        setTempVolume(device.volume);
        setTone(device.tone);
        setTempTone(device.tone);
        setAlarmVerification(device.alarmVerification === 1);
        setTempAlarmVerification(device.alarmVerification === 1);
        setAlarmVerificationDelay(device.alarmVerificationDelay);
        setTempAlarmVerificationDelay(device.alarmVerificationDelay);
        setTechnical(device.technical === 1);
        setTempTechnical(device.technical === 1);
        setPrealarm(device.prealarm === 1);
        setTempPrealarm(device.prealarm === 1);
        setPreAllTemp(device.preAllTemp);
        setTempPreAllTemp(device.preAllTemp);
        setSounderBase(device.sounderBase === 1);
        setTempSounderBase(device.sounderBase === 1);
        setDayNight(device.dayNight);
        setTempDayNight(device.dayNight);
        setDetectorDaySensitivity(device.detectorDaySensitivity);
        setTempDetectorDaySensitivity(device.detectorDaySensitivity);
        setIntendedDisChannel(device.intendedDisChannel);
        setTempIntendedDisChannel(device.intendedDisChannel);
        setDetectorSensitivityWeekCalendar(device.detectorSensitivityWeekCalendar);
        setTempDetectorSensitivityWeekCalendar(device.detectorSensitivityWeekCalendar);
        setSensSecDis(device.sensSecDis);

        switch (device.sensSecDis) {
          case 7:
            setTempSensSecDisDay(1);
            setTempSensSecDisNight(1);
            break;
          case 6:
            setTempSensSecDisDay(2);
            setTempSensSecDisNight(1);
            break;
          case 5:
            setTempSensSecDisDay(0);
            setTempSensSecDisNight(1);
            break;
          case 4:
            setTempSensSecDisDay(1);
            setTempSensSecDisNight(2);
            break;
          case 3:
            setTempSensSecDisDay(2);
            setTempSensSecDisNight(2);
            break;
          case 2:
            setTempSensSecDisDay(0);
            setTempSensSecDisNight(2);
            break;
          default:
            setTempSensSecDisDay(0);
            setTempSensSecDisNight(0);
        }

        setDetectorMultiSenseWeekCalendar(device.detectorMultiSenseWeekCalendar);
        setTempDetectorMultiSenseWeekCalendar(device.detectorMultiSenseWeekCalendar);
        setSelectWeeklyTrendBlock(device.detectorSensitivityWeekCalendar - 1);
        setSelectDeactivateWeeklyTrendBlock(device.detectorMultiSenseWeekCalendar - 1);
      }
    }
  }, [selectedDevice, selectedLoop, panel]);

  if (!panel || Object.keys(panel).length === 0) {
    return <div>No panel data found.</div>;
  }

  const loopCardNb = panel.VT5000_CONFIG.GeneralParameters?.loopCardNb || 0;
  const loopButtonsCount = 1 + 2 * loopCardNb;

  const handleLoopButtonClick = (index) => {
    setSelectedLoop(index);
  };

  const handleDeviceButtonClick = (index) => {
    setSelectedDevice(index);
    openPanel();
  };

  const handleWeeklyTrendBlockClick = (index) => {
    setSelectWeeklyTrendBlock(index);
    setTempDetectorSensitivityWeekCalendar(index + 1);
    setIsWeeklyTrendDialogOpen(false);
  };

  const handleDeactivateWeeklyTrendBlockClick = (index) => {
    setSelectDeactivateWeeklyTrendBlock(index);
    setTempDetectorMultiSenseWeekCalendar(index + 1);
    setIsDeactivateWeeklyTrendDialogOpen(false);
  };

  const renderPanelContent = () => {
    if (selectedDevice === null) return null;

    const i = selectedLoop;
    const j = selectedDevice;
    const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];
    const isProgrammed = device?.programmed === 1;
    const isModule = isProgrammed && (device.deviceType === 1);
    const isDevice = isProgrammed && (device.deviceType === 0);
    const isSmoke = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 0);
    const isThermal = isDevice && (device.lastTipo === 1);
    const isSmokeTherm = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 1);
    const isCallPoint = isModule && (device.lastTipo === 3) && (device.isCallPoint === 1);
    const isGas = isModule && (device.lastTipo === 3) && (device.isGas === 1);
    const isInput = isModule && (device.lastTipo === 3) && (!isCallPoint) && (!isGas);
    const isFlasher = isModule && (device.lastTipo === 4) && (device.isFlasher === 1);
    const isSiren = isModule && (device.lastTipo === 4) && (device.isSounder === 1);
    const isRepeater = isModule && (device.lastTipo === 4) && (device.isRepeater === 1);
    const isOutput = isModule && (device.lastTipo === 4) && (!isFlasher) && (!isSiren) && (!isRepeater);
    const isExpander = isModule && (device.lastTipo === 5);

    let deviceType = "Smoke";
    if (isThermal) {
      deviceType = "Thermal";
    } else if (isSmokeTherm) {
      deviceType = "Smoke Therm.";
    } else if (isCallPoint) {
      deviceType = "Call point";
    } else if (isGas) {
      deviceType = "Gas";
    } else if (isInput) {
      deviceType = "Input";
    } else if (isFlasher) {
      deviceType = "Flasher";
    } else if (isSiren) {
      deviceType = "Siren";
    } else if (isRepeater) {
      deviceType = "Rep.Led";
    } else if (isOutput) {
      deviceType = "Output";
    } else if (isExpander) {
      deviceType = "Expander";
    }

    if (device?.programmed === 1) {
      return (
        <div>
          <h2>
            {deviceType}
          </h2>
          <label htmlFor="nameInput">Module Name:</label>
          <input
            id="nameInput"
            type="text"
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
            placeholder={name}
          />
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Radio:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempIsRadio}
                onChange={() => setTempIsRadio(!tempIsRadio)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>
          {/* Reset Detectors */}
          {(isOutput || isFlasher || isSiren || isRepeater) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Activate Evacuation:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempEvacuationEnabled}
                onChange={() => setTempEvacuationEnabled(!tempEvacuationEnabled)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>)}
          {isSiren && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Volume:</span>
            <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
              <SpinButton
                min={0}
                max={31}
                value={tempVolume.toString()} // Ensure the value is a string
                onChange={(event, newValue) => {
                  const parsedValue = parseInt(newValue, 10);
                  if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 31) {
                    setTempVolume(parsedValue);
                  }
                }}
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                  input: { textAlign: "center", fontSize: "14px" },
                }}
              />
            </div>
          </div>)}
          {isSiren && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Tone:</span>
            <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
              <SpinButton
                min={0}
                max={4}
                value={tempTone.toString()} // Ensure the value is a string
                onChange={(event, newValue) => {
                  const parsedValue = parseInt(newValue, 10);
                  if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 31) {
                    setTempTone(parsedValue);
                  }
                }}
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                  input: { textAlign: "center", fontSize: "14px" },
                }}
              />
            </div>
          </div>)}
          {(isSmoke || isThermal || isSmokeTherm || isInput || isCallPoint || isGas) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Verify Alarms:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempAlarmVerification}
                onChange={() => setTempAlarmVerification(!tempAlarmVerification)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>)}
          {(isSmoke || isThermal || isSmokeTherm || isInput || isCallPoint || isGas) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Verify Seconds:</span>
            <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "100px" },
                }}
                options={Array.from({ length: 128 }, (_, i) => ({ key: i, text: `${i}` }))}
                selectedKey={tempAlarmVerificationDelay}
                onChange={(event, option) => setTempAlarmVerificationDelay(option.key)}
              />
            </div>
          </div>)}
          {(isInput || isCallPoint || isGas) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Technological:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempTechnical}
                onChange={() => setTempTechnical(!tempTechnical)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>)}
          {isGas && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Prealarm:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempPrealarm}
                onChange={() => setTempPrealarm(!tempPrealarm)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>)}
          {isGas && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Prealarm Period:</span>
            <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "100px" },
                }}
                options={Array.from({ length: 256 }, (_, i) => ({ key: i, text: `${i}` }))}
                selectedKey={tempPreAllTemp}
                onChange={(event, option) => setTempPreAllTemp(option.key)}
              />
            </div>
          </div>)}
          {(isSmoke || isThermal || isSmokeTherm) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Slave Sounder:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Toggle
                styles={{
                  root: { marginBottom: "0px", padding: "0px" },
                  label: { display: "none" }, // Hide Fluent UI’s built-in label
                  pill: { marginTop: "0px", alignSelf: "center" },
                  thumb: { marginTop: "0px" },
                }}
                checked={tempSounderBase}
                onChange={() => setTempSounderBase(!tempSounderBase)}
                onText="Yes"
                offText="No"
              />
            </div>
          </div>)}
          {(isSmoke || isSmokeTherm) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Management Sensitivity:</span>
            <div style={{ display: "flex", alignItems: "center", width: "120px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "120px" },
                }}
                options={[
                  { key: 0, text: "24H" },
                  { key: 1, text: "Night" },
                  { key: 2, text: "Fixed" },
                ]}
                selectedKey={tempDayNight} // ✅ Shows the default value
                onChange={(event, option) => setTempDayNight(option.key)}
              />
            </div>
          </div>)}
          {(isSmoke || isSmokeTherm) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Sensitivity:</span>
            <div style={{ display: "flex", alignItems: "center", width: "120px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "120px" },
                }}
                options={[
                  { key: 0, text: "Low" },
                  { key: 1, text: "Medium Low" },
                  { key: 2, text: "Medium High" },
                  { key: 3, text: "High" },
                ]}
                selectedKey={tempDetectorDaySensitivity} // ✅ Shows the default value
                onChange={(event, option) => setTempDetectorDaySensitivity(option.key)}
              />
            </div>
          </div>)}
          {isSmokeTherm && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Sensor Disablement:</span>
            <div style={{ display: "flex", alignItems: "center", width: "120px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "120px" },
                }}
                options={[
                  { key: 0, text: "None" },
                  { key: 1, text: "Thermal" },
                  { key: 2, text: "Smoke" },
                ]}
                selectedKey={tempIntendedDisChannel} // ✅ Shows the default value
                onChange={(event, option) => setTempIntendedDisChannel(option.key)}
              />
            </div>
          </div>)}
          {(isSmoke || isSmokeTherm) && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
            <span style={{ fontSize: "16px" }}>Weekly Trend:</span>
            <DefaultButton text={tempDetectorSensitivityWeekCalendar} onClick={() => setIsWeeklyTrendDialogOpen(true)} />
          </div>)}
          <Dialog
            hidden={!isWeeklyTrendDialogOpen}
            onDismiss={() => setIsWeeklyTrendDialogOpen(false)}
            minWidth={"1000px"}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Weekly Trend",
            }}
          >
            <div className="weeklyTrend-buttons-container">
              <div className="weeklyTrend-buttons-frame">
                {Array.from({ length: panel.VT5000_CONFIG.WeeklyTrends.length }, (_, i) => {
                  const isProgrammed = panel.VT5000_CONFIG.WeeklyTrends[i]?.programmed === 1;
                  return (
                    <button
                      key={i}
                      className={`weeklyTrend-button ${isProgrammed ? "programmed" : "not-programmed"} ${selectWeeklyTrendBlock === i ? "selected" : ""
                        }`}
                      onClick={() => handleWeeklyTrendBlockClick(i)}
                      disabled={!isProgrammed}
                    >
                      <span>{i + 1}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </Dialog>
          {(isSmoke || isSmokeTherm) && (<h5 style={{ marginTop: "10px" }}>
            Deactivate Part
          </h5>)}
          {isSmokeTherm && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Day:</span>
            <div style={{ display: "flex", alignItems: "center", width: "120px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "120px" },
                }}
                options={[
                  { key: 0, text: "None" },
                  { key: 1, text: "Thermal" },
                  { key: 2, text: "Smoke" },
                ]}
                selectedKey={tempSensSecDisDay} // ✅ Shows the default value
                onChange={(event, option) => setTempSensSecDisDay(option.key)}
              />
            </div>
          </div>)}
          {isSmokeTherm && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
            <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Night:</span>
            <div style={{ display: "flex", alignItems: "center", width: "120px" }}>
              <Dropdown
                styles={{
                  root: { marginBottom: "0px", padding: "0px", width: "100%" },
                  title: { height: "32px", lineHeight: "32px", textAlign: "center" },
                  dropdown: { width: "120px" },
                }}
                options={[
                  { key: 0, text: "None" },
                  { key: 1, text: "Thermal" },
                  { key: 2, text: "Smoke" },
                ]}
                selectedKey={tempSensSecDisNight} // ✅ Shows the default value
                onChange={(event, option) => setTempSensSecDisNight(option.key)}
              />
            </div>
          </div>)}
          {isSmokeTherm && (<div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
            <span style={{ fontSize: "16px" }}>Weekly Trend:</span>
            <DefaultButton text={tempDetectorMultiSenseWeekCalendar} onClick={() => setIsDeactivateWeeklyTrendDialogOpen(true)} />
          </div>)}
          <Dialog
            hidden={!isDeactivateWeeklyTrendDialogOpen}
            onDismiss={() => setIsDeactivateWeeklyTrendDialogOpen(false)}
            minWidth={"1000px"}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Weekly Trend",
            }}
          >
            <div className="weeklyTrend-buttons-container">
              <div className="weeklyTrend-buttons-frame">
                {Array.from({ length: panel.VT5000_CONFIG.WeeklyTrends.length }, (_, i) => {
                  const isProgrammed = panel.VT5000_CONFIG.WeeklyTrends[i]?.programmed === 1;
                  return (
                    <button
                      key={i}
                      className={`weeklyTrend-button ${isProgrammed ? "programmed" : "not-programmed"} ${selectDeactivateWeeklyTrendBlock === i ? "selected" : ""
                        }`}
                      onClick={() => handleDeactivateWeeklyTrendBlockClick(i)}
                      disabled={!isProgrammed}
                    >
                      <span>{i + 1}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </Dialog>
        </div>
      );
    } else {
      // Content for unprogrammed devices
      return (
        <div>
          <h4>Device</h4>
          <div>
            <DefaultButton text="Smoke" />
            <DefaultButton text="Thermal" />
            <DefaultButton text="Smoke Therm." />
          </div>
          <h4>Module</h4>
          <div>
            <DefaultButton text="Input" />
            <DefaultButton text="Call point" />
            <DefaultButton text="Gas" />
            <DefaultButton text="Output" />
            <DefaultButton text="Flasher" />
            <DefaultButton text="Siren" />
            <DefaultButton text="Rep.Led" />
            <DefaultButton text="XPander" />
          </div>
        </div>
      );
    }
  };

  const onSave = () => {
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].name = tempName;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].isRadio = tempIsRadio ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].evacuationEnabled = tempEvacuationEnabled ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].volume = tempVolume;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].tone = tempTone;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].alarmVerification = tempAlarmVerification ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].alarmVerificationDelay = tempAlarmVerificationDelay;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].technical = tempTechnical ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].prealarm = tempPrealarm ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].preAllTemp = tempPreAllTemp;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sounderBase = tempSounderBase ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].dayNight = tempDayNight;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorDaySensitivity = tempDetectorDaySensitivity;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].intendedDisChannel = tempIntendedDisChannel;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorSensitivityWeekCalendar = tempDetectorSensitivityWeekCalendar;
    if (tempSensSecDisNight === 1) {
      if (tempSensSecDisDay === 0) {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      } else if (tempSensSecDisDay === 1) {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      } else {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      }
    } else if (tempSensSecDisNight === 2) {
      if (tempSensSecDisDay === 0) {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      } else if (tempSensSecDisDay === 1) {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      } else {
        panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
      }
    } else {
      panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = 5;
    }
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorMultiSenseWeekCalendar = tempDetectorMultiSenseWeekCalendar;

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_devices`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      argusTablePr: panel.VT5000_CONFIG.ArgusTablePr,
      weeklyTrends: panel.VT5000_CONFIG.WeeklyTrends
    })
      .then((response) => {
        if (response.data.success) {
          console.log("Devices settings updated successfully");
        } else {
          console.error("Failed to update devices settings");
          resetPanel();
        }
      })
      .catch((error) => {
        console.error("Error updating devices settings:", error);
        resetPanel();
      });
    dismissPanel();
  };

  const onCancel = () => {
    setTempName(name);
    setTempIsRadio(isRadio);
    setTempEvacuationEnabled(evacuationEnabled);
    setTempVolume(volume);
    setTempTone(tone);
    setTempAlarmVerification(alarmVerification);
    setTempAlarmVerificationDelay(alarmVerificationDelay);
    setTempTechnical(technical);
    setTempPrealarm(prealarm);
    setTempPreAllTemp(preAllTemp);
    setTempSounderBase(sounderBase);
    setTempDayNight(dayNight);
    setTempDetectorDaySensitivity(detectorDaySensitivity);
    setTempIntendedDisChannel(intendedDisChannel);
    setTempDetectorSensitivityWeekCalendar(detectorSensitivityWeekCalendar);
    switch (sensSecDis) {
      case 7:
        setTempSensSecDisDay(1);
        setTempSensSecDisNight(1);
        break;
      case 6:
        setTempSensSecDisDay(2);
        setTempSensSecDisNight(1);
        break;
      case 5:
        setTempSensSecDisDay(0);
        setTempSensSecDisNight(1);
        break;
      case 4:
        setTempSensSecDisDay(1);
        setTempSensSecDisNight(2);
        break;
      case 3:
        setTempSensSecDisDay(2);
        setTempSensSecDisNight(2);
        break;
      case 2:
        setTempSensSecDisDay(0);
        setTempSensSecDisNight(2);
        break;
      default:
        setTempSensSecDisDay(0);
        setTempSensSecDisNight(0);
    }
    setTempDetectorMultiSenseWeekCalendar(detectorMultiSenseWeekCalendar);
    dismissPanel();
  };

  const resetPanel = () => {
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].name = name;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].isRadio = tempIsRadio ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].evacuationEnabled = evacuationEnabled ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].volume = volume;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].tone = tone;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].alarmVerification = alarmVerification ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].alarmVerificationDelay = alarmVerificationDelay;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].technical = technical ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].prealarm = prealarm ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].preAllTemp = preAllTemp;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sounderBase = sounderBase ? 1 : 0;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].dayNight = dayNight;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorDaySensitivity = detectorDaySensitivity;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].intendedDisChannel = intendedDisChannel;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorSensitivityWeekCalendar = detectorSensitivityWeekCalendar;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].sensSecDis = sensSecDis;
    panel.VT5000_CONFIG.ArgusTablePr[selectedLoop][selectedDevice].detectorMultiSenseWeekCalendar = detectorMultiSenseWeekCalendar;
  }

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={onSave} styles={{ root: { marginRight: 8 } }}>
        Save
      </PrimaryButton>
      <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
    </div>
  );

  return (
    <div className="devices-content">
      {/* Loop Buttons */}
      <div className="loop-buttons">
        <span>Loop:</span>
        {Array.from({ length: loopButtonsCount }, (_, index) => (
          <button
            key={index}
            className={`loop-button ${selectedLoop === index ? "selected-loop" : ""}`}
            onClick={() => handleLoopButtonClick(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Device Buttons */}
      <div className="device-buttons-container">
        <div className="device-buttons-frame">
          {Array.from({ length: panel.VT5000_CONFIG.ArgusTablePr[0].length }, (_, index) => {
            const i = selectedLoop; // Loop index
            const j = index; // Device index
            const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];
            const isProgrammed = device?.programmed === 1;
            const isModule = isProgrammed && (device.deviceType === 1);
            const isDevice = isProgrammed && (device.deviceType === 0);
            const isSmoke = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 0);
            const isThermal = isDevice && (device.lastTipo === 1);
            const isSmokeTherm = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 1);
            const isCallPoint = isModule && (device.lastTipo === 3) && (device.isCallPoint === 1);
            const isGas = isModule && (device.lastTipo === 3) && (device.isGas === 1);
            const isInput = isModule && (!isCallPoint) && (!isGas);
            const isFlasher = isModule && (device.lastTipo === 4) && (device.isFlasher === 1);
            const isSiren = isModule && (device.lastTipo === 4) && (device.isSounder === 1);
            const isRepeater = isModule && (device.lastTipo === 4) && (device.isRepeater === 1);
            const isOutput = isModule && (!isFlasher) && (!isSiren) && (!isRepeater);
            const isExpander = isModule && (device.lastTipo === 5);

            let buttonClass = "not-programmed"; // Default class
            // if (isProgrammed) {
            //   buttonClass = device?.deviceType === 0 ? "sensor" : "input";
            // }
            if (isModule) {
              buttonClass = "module";
            }
            else if (isSmoke) {
              buttonClass = "smoke";
            }
            else if (isThermal) {
              buttonClass = "heat";
            }
            else if (isSmokeTherm) {
              buttonClass = "smokeTherm";
            }

            return (
              <button
                key={index}
                className={`device-button ${buttonClass}`}
                onClick={() => handleDeviceButtonClick(index)}
              >
                <span>{index + 1}</span>
              </button>
            );
          })}
        </div>
      </div>

      {/* Fluent UI Panel */}
      <Panel
        isOpen={isPanelOpen}
        onDismiss={dismissPanel}
        headerText={`Device ${selectedDevice !== null ? selectedDevice + 1 : ""}`}
        type={PanelType.custom}
        customWidth="400px"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        {renderPanelContent()}
      </Panel>
    </div>
  );
};

export default DevicesContent;
