import React, { useState, useEffect } from "react";
import Datetime from "../../Assets/icons/Datetime.svg";
import Summertime from "../../Assets/icons/Summertime.svg";
import Holiday from "../../Assets/icons/Holiday.svg";
import Time from "../../Assets/icons/Time.svg";
import Calendar from "../../Assets/icons/Calendar.svg";

const ClockFrame = ({ setCurrentFrame }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
      const updateTime = () => {
        const now = new Date();
        let formattedTime = now.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }).replace(/,/g, ""); // Remove comma between date and time
  
        formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
        setCurrentTime(formattedTime);
      };
  
      updateTime();
      const interval = setInterval(updateTime, 1000);
      return () => clearInterval(interval);
    }, []);


  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Clock</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "4px 0" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1px", width: "98%", height: "36%", textAlign: "center", margin: "0 0 1px 0" }}>
        {[{ icon: Datetime, text: "Date and Time", disabled: true },
        { icon: Summertime, text: "Summertime", onClick: () => setCurrentFrame("summertime") },
        { icon: Holiday, text: "Holidays", onClick: () => setCurrentFrame("holidays") },].map((btn, index) => (
          <button key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", fontSize: "30px", width: "100%", background: btn.disabled ? "#ccc" : "none", borderRadius: "5px", cursor: btn.disabled ? "not-allowed" : "pointer", opacity: btn.disabled ? 0.5 : 1, }}
            onClick={btn.disabled ? undefined : btn.onClick}
            disabled={btn.disabled}
          >
            <img src={btn.icon} alt={btn.text} style={{ width: "70px", height: "70px", marginBottom: "10px" }} />
            {btn.text}
          </button>
        ))}
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "1px", width: "98%", height: "36%", textAlign: "center" }}>
        {[{ icon: Time, text: "Time Slot", onClick: () => setCurrentFrame("timeSlot") },
        { icon: Calendar, text: "Weekly Trends", onClick: () => setCurrentFrame("weeklyTrends") },].map((btn, index) => (
          <button key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", fontSize: "30px", width: "100%", background: btn.disabled ? "#ccc" : "none", borderRadius: "5px", cursor: btn.disabled ? "not-allowed" : "pointer", opacity: btn.disabled ? 0.5 : 1, }}
            onClick={btn.disabled ? undefined : btn.onClick}
            disabled={btn.disabled}
          >
            <img src={btn.icon} alt={btn.text} style={{ width: "70px", height: "70px", marginBottom: "10px" }} />
            {btn.text}
          </button>
        ))}
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
          <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("configure")}>Back</button>
        </div>
      </div>
    );
};

export default ClockFrame;
