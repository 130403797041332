import React, { useState, useEffect } from "react";
import { Toggle } from "@fluentui/react";
import Axios from "axios";

const PasswordsFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [password1, setPassword1] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.password1 || 0);
    const [password2, setPassword2] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.password2 || 0);
    const [password3, setPassword3] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.password3 || 0);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            panel.VT5000_CONFIG.GeneralParameters.password1 = password1 ? 1 : 0;
            panel.VT5000_CONFIG.GeneralParameters.password2 = password2 ? 1 : 0;
            panel.VT5000_CONFIG.GeneralParameters.password3 = password3 ? 1 : 0;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                generalparameters: panel.VT5000_CONFIG.GeneralParameters
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Loop settings updated successfully");
                    } else {
                        console.error("Failed to update loop settings");
                    }
                })
                .catch((error) => {
                    console.error("Error updating loop settings:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Passwords</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "80%", marginTop: "20px" }}>
                <span style={{ fontSize: "30px", textAlign: "left" }}>Programming</span>
                <button 
                    style={{ fontSize: "30px", padding: "10px 20px", borderRadius: "5px", border: "1px solid black", background: "white", cursor: "pointer" }}
                    onClick={() => setCurrentFrame("modifyPassword")}
                >
                    Change Password
                </button>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", width: "80%" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>1 Operating Password Enabled?</span>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", alignSelf: "center" }}>
                        <Toggle
                            checked={password1}
                            onChange={() => setPassword1(!password1)}
                            styles={{ pill: { background: password1 ? "#C00D0D" : "#FFF" }, root: { alignSelf: "center" } }}
                        />
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{password1 ? "Yes" : "No"}</span>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>2 Operating Password Enabled?</span>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", alignSelf: "center" }}>
                        <Toggle
                            checked={password2}
                            onChange={() => setPassword2(!password2)}
                            styles={{ pill: { background: password2 ? "#C00D0D" : "#FFF" }, root: { alignSelf: "center" } }}
                        />
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{password2 ? "Yes" : "No"}</span>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>3 Operating Password Enabled?</span>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", alignSelf: "center" }}>
                        <Toggle
                            checked={password3}
                            onChange={() => setPassword3(!password3)}
                            styles={{ pill: { background: password3 ? "#C00D0D" : "#FFF" }, root: { alignSelf: "center" } }}
                        />
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{password3 ? "Yes" : "No"}</span>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("configure")}>Back</button>
            </div>
        </div>
    );
};

export default PasswordsFrame;
