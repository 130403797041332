import React, { useState, useEffect } from "react";
import PanelInfo from "../../Assets/icons/PanelInfo.svg";
import Led from "../../Assets/icons/Led.svg";
import Supervision from "../../Assets/icons/Supervision.svg";
import Various from "../../Assets/icons/Various.svg";
import LoopType from "../../Assets/icons/LoopType.svg";
import Language from "../../Assets/icons/Language.svg";

const GeneralFrame = ({ setCurrentFrame }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
      const updateTime = () => {
        const now = new Date();
        let formattedTime = now.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        }).replace(/,/g, ""); // Remove comma between date and time
  
        formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
        setCurrentTime(formattedTime);
      };
  
      updateTime();
      const interval = setInterval(updateTime, 1000);
      return () => clearInterval(interval);
    }, []);


  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>General</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1px", width: "98%", height: "72%", textAlign: "center" }}>
        {[{ icon: PanelInfo, text: "Panel Info", onClick: () => setCurrentFrame("panelInfo") },
        { icon: Led, text: "Buzzer Led", onClick: () => setCurrentFrame("buzzerLed") },
        { icon: Supervision, text: "Supervision", onClick: () => setCurrentFrame("supervision") },
        { icon: Various, text: "Various", onClick: () => setCurrentFrame("various") },
        { icon: LoopType, text: "Loop Type", onClick: () => setCurrentFrame("loopType") },
        { icon: Language, text: "Language", onClick: () => setCurrentFrame("language") },].map((btn, index) => (
          <button key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", fontSize: "30px", width: "100%", background: btn.disabled ? "#ccc" : "none", borderRadius: "5px", cursor: btn.disabled ? "not-allowed" : "pointer", opacity: btn.disabled ? 0.5 : 1, }}
            onClick={btn.disabled ? undefined : btn.onClick}
            disabled={btn.disabled}
          >
            <img src={btn.icon} alt={btn.text} style={{ width: "70px", height: "70px", marginBottom: "10px" }} />
            {btn.text}
          </button>
        ))}
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
          <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("system")}>Back</button>
        </div>
      </div>
    );
};

export default GeneralFrame;
