import React, { useState, useEffect } from "react";
import { ChoiceGroup, TextField } from "@fluentui/react";
import Axios from "axios";

const PanelInfoFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [panelName, setPanelName] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.panelName || "");
    const [supportCenter, setSupportCenter] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.assistanceName || "");
    const [panelColour, setPanelColour] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.panelColour === 0 ? "Black" : panel?.VT5000_CONFIG?.GeneralParameters?.panelColour === 1 ? "Blue" : "Green");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            panel.VT5000_CONFIG.GeneralParameters.panelName = panelName;
            panel.VT5000_CONFIG.GeneralParameters.assistanceName = supportCenter;
            if (panelColour === "Black") {
                panel.VT5000_CONFIG.GeneralParameters.panelColour = 0;
            }
            else if (panelColour === "Blue") {
                panel.VT5000_CONFIG.GeneralParameters.panelColour = 1;
            }
            else {
                panel.VT5000_CONFIG.GeneralParameters.panelColour = 2;
            }

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                generalparameters: panel.VT5000_CONFIG.GeneralParameters
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Loop settings updated successfully");
                    } else {
                        console.error("Failed to update loop settings");
                    }
                })
                .catch((error) => {
                    console.error("Error updating loop settings:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Panel Info</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", width: "80%" }}>
                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Panel Name</span>
                    <TextField value={panelName} onChange={(e, newValue) => setPanelName(newValue.slice(0, 31))} styles={{ root: { width: "30%" } }} />
                </div>
                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Support Center</span>
                    <TextField value={supportCenter} onChange={(e, newValue) => setSupportCenter(newValue.slice(0, 31))} styles={{ root: { width: "30%" } }} />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Background</span>
                    <ChoiceGroup
                        selectedKey={panelColour}
                        options={[
                            { key: "Black", text: "Black" },
                            { key: "Blue", text: "Blue" },
                            { key: "Green", text: "Green" },
                        ]}
                        onChange={(e, option) => setPanelColour(option.key)}
                        styles={{ root: { display: "flex", flexDirection: "row", alignItems: "center" }, flexContainer: { display: "flex", flexDirection: "row", gap: "15px" } }}
                    />
                </div>
            </div>

            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("general")}>Back</button>
            </div>
        </div>
    );
};

export default PanelInfoFrame;
