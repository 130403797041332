import React, { useState, useEffect } from "react";
import "../../Styles/AddWeeklyTrendsFrame.css";

const AddWeeklyTrendsFrame = ({ setCurrentFrame, setSelectedWeeklyTrendIndex, panel }) => {
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Add Weekly Trends</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0 0 0" }} />
            <div className="weeklyTrend-buttons-container">
                <div className="weeklyTrend-buttons-frame">
                    {panel.VT5000_CONFIG.WeeklyTrends.map((trend, index) => (
                        <button
                            key={index}
                            className={`weeklyTrend-button ${trend.programmed === 1 ? "programmed" : "not-programmed"}`}
                            disabled={trend.programmed === 1}
                            onClick={() => {
                                setSelectedWeeklyTrendIndex(index);
                                setCurrentFrame("updateAddWeeklyTrends");
                            }}
                        >
                            <span>{index + 1}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("weeklyTrends")}>Back</button>
            </div>
        </div>
    );
};

export default AddWeeklyTrendsFrame;
