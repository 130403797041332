import React, { useState, useEffect } from "react";

const ModifyPasswordFrame = ({ setCurrentFrame, panel }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [password, setPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let formattedTime = now.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).replace(/,/g, ""); // Remove comma between date and time

      formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = (value) => {
    if (value === "C") {
      setPassword(password.slice(0, -1));
      setMaskedPassword(maskedPassword.slice(0, -1));
    } else if (value === "OK") {
      localStorage.setItem("tempPassword", Number(password));
      setCurrentFrame("confirmPassword");
    } else if (password.length < 5) {
      setPassword(password + value);
      setMaskedPassword(maskedPassword + "*");
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 30, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Modify Programming Password</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 0 0 10px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "0px" }}>
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "C", "OK"].map((btn) => (
            <button key={btn} style={{ padding: "30px", fontSize: "40px", background: "none", borderRadius: "5px" }} onClick={() => handleButtonClick(btn)}>{btn}</button>
          ))}
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
          <input type="text" value={maskedPassword} readOnly maxLength="5" style={{ fontSize: "40px", textAlign: "center", width: "99%", height: "33%", marginBottom: "15px", borderRadius: "5px" }} />
          <span style={{ fontSize: 60 }}>Insert Password</span>
        </div>
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("passwords")}>Back</button>
      </div>
    </div>
  );
};

export default ModifyPasswordFrame;
