import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import {
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavItem,
  NavSectionHeader,
  NavSubItem,
  Hamburger,
} from "@fluentui/react-nav-preview";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import ConfigurationContent from "./ConfigurationContent";
import DevicesContent from "./DevicesContent";
import LogicFunctionContent from "./LogicFunctionContent";
import ZonesContent from "./ZonesContent";
import OutputZonesContent from "./OutputZonesContent";
import MainContent from "./MainContent";
import "../Styles/PanelType.css";
import ConfigFile from "./ParsePanelData";
import defaultPanelData from "../Assets/defaultPanelData/defaultPanelData.json";

class Panel {
  constructor(configFile) {
    // Ensure VT5000_CONFIG is always initialized
    this.VT5000_CONFIG = configFile?.VT5000_CONFIG || {
      GeneralParameters: {},
      ArgusTablePr: [],
      Codes: {},
      DaylightSaving: {},
      FlogicPr: [],
      Holidays: [],
      OutputZones: [],
      TimeIntervals: [],
      VTNet: [],
      WeeklyTrends: [],
      ZoneTable: [],
    };
  }
}

const subNavItems = ["Configuration", "Devices", "Logic Function", "Zones", "Output Zones"];

const parsePanelsToConfig = (panelData) => {
  const configFile = new ConfigFile();

  // Safely parse and set defaults
  configFile.VT5000_CONFIG = {
    ArgusTablePr: panelData.argustablepr || [],
    Codes: panelData.codes || {},
    DaylightSaving: panelData.daylightsaving || {},
    FlogicPr: panelData.flogicpr || [],
    GeneralParameters: panelData.generalparameters || {},
    Holidays: panelData.holidays || [],
    OutputZones: panelData.outputzones || [],
    TimeIntervals: panelData.timeintervals || [],
    VTNet: panelData.vtnets || [],
    WeeklyTrends: panelData.weeklytrends || [],
    ZoneTable: panelData.zonetable || [],
  };

  return configFile;
};

const PanelType = () => {
  const [panelData, setPanelData] = useState([]);
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(null);
  const [selectedSubNavItem, setSelectedSubNavItem] = useState("Main");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPanelName, setNewPanelName] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPanels();
  }, []);

  const fetchPanels = () => {
    const email = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/get_panels`, { email })
      .then((response) => {
        if (response.data.success) {
          const panels = response.data.panels.map((panelData) => new Panel(parsePanelsToConfig(panelData)));
          console.log(panels);
          setPanelData(panels);
        } else {
          console.error("Failed to fetch panels.");
        }
      })
      .catch((error) => console.error("An error occurred while fetching panels:", error));
  };

  const handleCreatePanel = () => {
    const email = localStorage.getItem("userEmail");
    
    // Check if panel with the same name already exists
    if (panelData.some(panel => panel.panel_name === newPanelName)) {
      toast.warning("A panel with this name already exists!");
      return;
    }

    let newPanelData = { ...defaultPanelData };
    // , generalParameters: { panelName: newPanelName, loopCardNb: selectedCheckbox } 
    newPanelData.GeneralParameters.panelName = newPanelName;
    newPanelData.GeneralParameters.loopCardNb = selectedCheckbox;

    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/check_panel_exist`, {
      email,
      panelName: newPanelName,
      panel: newPanelData,
    })
      .then((response) => {
        if (response.data.success) {
          toast.success("Panel created successfully!");
          fetchPanels();
          setIsDialogOpen(false);
          setNewPanelName("");
        } else {
          toast.error("Failed to create panel.");
        }
      })
      .catch((error) => {
        console.error("Error creating panel:", error);
        toast.error("Error creating panel!");
      });
  };

  const updatePanels = () => {
    fetchPanels();
  };

  const handleNavItemClick = (index) => {
    setSelectedPanelIndex(index);
    setSelectedSubNavItem("Main"); // Default to Main when a panel is selected
  };

  const handleSubNavItemClick = (subNavItem) => {
    setSelectedSubNavItem(subNavItem);
  };

  const handleCheckboxChange = (index) => {
    setSelectedCheckbox(index);
};

  // Render specific content based on the selected subnav item
  const renderContent = () => {
    const selectedPanel = panelData[selectedPanelIndex];

    if (!selectedPanel) {
      return <div>Select a panel to see its content.</div>;
    }

    switch (selectedSubNavItem) {
      case "Configuration":
        return <ConfigurationContent panel={selectedPanel} setSelectedSubNavItem={setSelectedSubNavItem} />;
      case "Devices":
        return <DevicesContent panel={selectedPanel} />;
      case "Logic Function":
        return <LogicFunctionContent panel={selectedPanel} />;
      case "Zones":
        return <ZonesContent panel={selectedPanel} />;
      case "Output Zones":
        return <OutputZonesContent panel={selectedPanel} />;
      default:
        return <MainContent panel={selectedPanel} updatePanels={updatePanels} />;
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh", flexDirection: "row" }}>
      <NavDrawer open={true} type="inline" style={{ width: "250px" }}>
        <NavDrawerHeader>
          <Hamburger onClick={() => navigate("/dashboard")} />
        </NavDrawerHeader>
        <NavDrawerBody>
          <NavSectionHeader>Panels</NavSectionHeader>
          <Button onClick={() => setIsDialogOpen(true)}>New Panel</Button>
          {panelData.map((panel, index) => (
            <React.Fragment key={index}>
              <NavItem
                onClick={() => handleNavItemClick(index)}
                style={{
                  color: selectedPanelIndex === index ? "#c00d0d" : undefined,
                }}
              >
                {panel.VT5000_CONFIG.GeneralParameters?.panelName.replace(/\u0000/g, '') || "Unnamed Panel"}
              </NavItem>
              {selectedPanelIndex === index && (
                <div style={{ marginLeft: "20px" }}>
                  {subNavItems.map((subItem, subIndex) => (
                    <NavSubItem
                      key={subIndex}
                      onClick={() => handleSubNavItemClick(subItem)}
                      style={{
                        color: selectedSubNavItem === subItem ? "#c00d0d" : undefined,
                      }}
                    >
                      {subItem}
                    </NavSubItem>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </NavDrawerBody>
      </NavDrawer>

      {/* Dynamic Content Area */}
      <div style={{ flex: 1, padding: "16px", color: "black", overflow: "auto" }}>
        {renderContent()}
      </div>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>CREATE NEW PANEL</DialogTitle>
        <DialogContent>
          <TextField
            label="Panel Name"
            fullWidth
            value={newPanelName}
            style={{marginTop: "10px"}}
            onChange={(e) => setNewPanelName(e.target.value)}
          />
          <div style={{ fontSize: "20px", marginBottom: "10px", marginTop: "9%" }}>Loop Expansion Cards</div>
          <div style={{ display: "flex", justifyContent: "center", gap: "1px" }}>
              {[0, 1, 2, 3, 4].map((num) => (
                  <div key={num} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60px", height: "60px", border: "2px solid black", borderRadius: "5px", cursor: "pointer", background: selectedCheckbox === num ? "#C00D0D" : "#FFF" }}
                      onClick={() => handleCheckboxChange(num)}>
                      <span style={{ fontSize: "30px", fontWeight: "bold", color: selectedCheckbox === num ? "#FFF" : "#000" }}>{num}</span>
                  </div>
              ))}
          </div>
          <div style={{ fontSize: "20px", marginTop: "10px" }}>Each card is equal to 2 Loop</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreatePanel} color="primary">Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PanelType;
