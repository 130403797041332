import React, { useState, useEffect } from "react";
import { Toggle } from "@fluentui/react";
import Axios from "axios";

const LoopTypeFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [toggles, setToggles] = useState(Array(9).fill(false));
    const loopCardNb = panel?.VT5000_CONFIG?.GeneralParameters?.loopCardNb || 0;
    const maxEditableToggles = 1 + 2 * loopCardNb;

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            const initialToggles = Array(9).fill(false).map((_, index) =>
                panel.VT5000_CONFIG.GeneralParameters[`loop${index + 1}Closed`] === 0
            );
            setToggles(initialToggles);
        }
    }, [panel]);

    const toggleSwitch = (index) => {
        setToggles((prevToggles) => {
            const newToggles = [...prevToggles];
            newToggles[index] = !newToggles[index];
            return newToggles;
        });
    };

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            toggles.forEach((state, index) => {
                panel.VT5000_CONFIG.GeneralParameters[`loop${index + 1}Closed`] = state ? 0 : 1;
            });

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                generalparameters: panel.VT5000_CONFIG.GeneralParameters
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Loop settings updated successfully");
                    } else {
                        console.error("Failed to update loop settings");
                    }
                })
                .catch((error) => {
                    console.error("Error updating loop settings:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Loop Type</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />

            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", rowGap: "20px", gap: "10px", width: "98%", height: "70%", textAlign: "center", justifyItems: "center", alignItems: "center" }}>
                {toggles.map((state, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <span style={{ fontSize: "30px", fontWeight: "bold", margin: "0 20px 10px 0" }}>{index + 1}</span>
                        <Toggle
                            checked={state}
                            onChange={() => toggleSwitch(index)}
                            inlineLabel
                            disabled={index >= maxEditableToggles}
                            styles={{
                                root: { transform: "scale(1.5)", marginBottom: "10px" },
                                pill: { background: state ? "#C00D0D" : "#ccc", height: "40px", width: "80px" },
                                thumb: { background: "white", height: "30px", width: "30px" }
                            }}
                        />
                    </div>
                ))}
            </div>

            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("general")}>Back</button>
            </div>
        </div>
    );
};

export default LoopTypeFrame;
