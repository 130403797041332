import React, { useEffect, useState, useRef } from 'react';
import "../Styles/register.css";
import Img from "../Assets/icons/VELOXLogo.png";
import * as yup from "yup";
import { ErrorMessage, Formik, Form, Field } from "formik";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function Register({ logado = false }) {
  const navigate = useNavigate();
  const [isIdLocked, setIsIdLocked] = useState(false);
  const [isVerifiedID, setIsVerifiedID] = useState(false);
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);
  const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false);
  // const [remainingTime, setRemainingTime] = useState(null);
  const [hoverMessage, setHoverMessage] = useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [openYubikeyDialog, setOpenYubikeyDialog] = useState(false);
  const [yubikeyCode, setYubikeyCode] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const yubikeyInputRef = useRef(null);
  const dialogContentRef = useRef(null);

  const handleVerifyId = (values) => {
    console.log(values);
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/verify_id`, { id: values.id, verificationCode: values.verificationCode })
      .then((response) => {
        console.log('Verification code stored:', response.data);
        if (response.data.success) {
          toast.success(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsIdLocked(true);
          setIsVerifiedID(true);
          setIsVerifyDisabled(true);
        } else {
          toast.error(`${response.data.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error('Verification error.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Verification error:', error);
      });
  };

  const sendEmail = (values) => {
    if (!isVerifiedID) {
      toast.info("Please verify your ID!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!values.firstname) {
      toast.info("Please enter your name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/try_send_email`, {
      id: values.id,
      email: values.email,
      firstname: values.firstname,
    }).then(response => {
      if (response.data.success) {
        if (response.data.disableTime < 600000) {
          toast.success('Please use the email verification code you recently received, or click the Send button again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else {
          toast.success('Verification code sent successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        disableEmailButton(response.data.disableTime);
      }
      else {
        toast.error('Failed to send verification code, please try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch(error => {
      toast.error('Failed to send verification code, please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const handleRegister = (values) => {
    if (!isVerifiedID) {  // Check if the ID is verified
      toast.info("Please verify your ID!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;  // Stop further execution if not verified
    }

    if (!isEmailButtonDisabled) {
      toast.info("Please click SEND EMAIL to verify your email!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const countrySelect = document.querySelector('.country');
    const stateSelect = document.querySelector('.state');
    const citySelect = document.querySelector('.city');

    const selectedCountry = countrySelect.options[countrySelect.selectedIndex].text;
    const selectedState = stateSelect.options[stateSelect.selectedIndex].text;
    const selectedCity = citySelect.options[citySelect.selectedIndex].text;

    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, {
      email: values.email,
      emailverifycode: values.emailcode,
    }).then((response) => {
      if (response.data.success) {
        setCountry(selectedCountry);
        setState(selectedState);
        setCity(selectedCity);
        toast.success("Email verification successful, please register your YubiKey", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        handleOpenYubikeyDialog();
      }
      else {
        toast.error(`${response.data.msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch((error) => {
      toast.error("Register error, please try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Register error:', error);
    });
  };

  const handleRegisterYubikey = (values) => {
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/register_yubikey`, {
      id: values.id,
      companyname: values.companyname,
      country: country,
      state: state,
      city: city,
      firstname: values.firstname,
      lastname: values.lastname,
      password: values.password,
      yubikeyContent: yubikeyCode,
    }).then((response) => {
      if (response.data.success) {
        localStorage.setItem('registerSuccessMessage', response.data.msg);
        handleCloseYubikeyDialog(); // Close the dialog
        navigate('/');
      } else {
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        if (yubikeyInputRef.current) {
          yubikeyInputRef.current.focus();  // Set focus back to the input field even if there's an error
        }
      }
      setYubikeyCode('');
    }).catch((error) => {
      toast.error("Register YubiKey failed, please try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Verification error:', error);
      setYubikeyCode('');
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();  // Set focus back to the input field even if there's an error
      }
    });
  };

  const handleOpenYubikeyDialog = () => {
    setOpenYubikeyDialog(true);
  };

  const handleCloseYubikeyDialog = () => {
    setYubikeyCode('');
    setOpenYubikeyDialog(false);
  };

  const disableEmailButton = (remainingTime) => {
    setIsEmailButtonDisabled(true); // Disable the button
    const currentTime = Date.now();

    const interval = setInterval(() => {
      const elapsed = Date.now() - currentTime;
      const remaining = remainingTime - elapsed;

      if (remaining <= 0) {
        clearInterval(interval);
        setIsEmailButtonDisabled(false); // Re-enable the button after 10 minutes
        setHoverMessage(''); // Clear hover message when time is up
      }
    }, 1);
  };

  const config = {
    cUrl: 'https://api.countrystatecity.in/v1/countries',
    ckey: 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA=='
  };

  const [stateSelectDisabled, setStateSelectDisabled] = useState(true);
  const [citySelectDisabled, setCitySelectDisabled] = useState(true);

  const loadStates = () => {
    const stateSelect = document.querySelector('.state');
    const citySelect = document.querySelector('.city');
    const countrySelect = document.querySelector('.country');
    const selectedCountryCode = countrySelect.value;

    setStateSelectDisabled(false);
    setCitySelectDisabled(true);

    stateSelect.innerHTML = '<option value="">Select State</option>';
    citySelect.innerHTML = '<option value="">Select City</option>';

    fetch(`${config.cUrl}/${selectedCountryCode}/states`, { headers: { "X-CSCAPI-KEY": config.ckey } })
      .then(response => response.json())
      .then(data => {
        data.forEach(state => {
          const option = document.createElement('option');
          option.value = state.iso2;
          option.textContent = state.name;
          stateSelect.appendChild(option);
        });
      })
      .catch(error => console.error('Error loading states:', error));
  };

  const loadCities = () => {
    const citySelect = document.querySelector('.city');
    const countrySelect = document.querySelector('.country');
    const stateSelect = document.querySelector('.state');
    const selectedCountryCode = countrySelect.value;
    const selectedStateCode = stateSelect.value;

    setCitySelectDisabled(false);

    citySelect.innerHTML = '<option value="">Select City</option>';

    fetch(`${config.cUrl}/${selectedCountryCode}/states/${selectedStateCode}/cities`, { headers: { "X-CSCAPI-KEY": config.ckey } })
      .then(response => response.json())
      .then(data => {
        data.forEach(city => {
          const option = document.createElement('option');
          option.value = city.iso2;
          option.textContent = city.name;
          citySelect.appendChild(option);
        });
      })
      .catch(error => console.error('Error loading cities:', error));
  };

  useEffect(() => {
    const countrySelect = document.querySelector('.country');

    fetch(config.cUrl, { headers: { "X-CSCAPI-KEY": config.ckey } })
      .then(response => response.json())
      .then(data => {
        data.forEach(country => {
          const option = document.createElement('option');
          option.value = country.iso2;
          option.textContent = country.name;
          countrySelect.appendChild(option);
        });
      })
      .catch(error => console.error('Error loading countries:', error));
  }, [config.cUrl, config.ckey]);

  useEffect(() => {
    if (isHovering && isEmailButtonDisabled) {
      const currentTime = Date.now();
      const remainingTime = 10 * 60 * 1000 - (currentTime % (10 * 60 * 1000)); // Example time logic

      // Immediately calculate and show the hover message when hovering starts
      const minutesLeft = Math.floor(remainingTime / 60000);
      const secondsLeft = Math.floor((remainingTime % 60000) / 1000);
      setHoverMessage(`Please wait ${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft} before you try again`);

      // Continue updating the hover message every second
      const interval = setInterval(() => {
        const elapsed = Date.now() - currentTime;
        const remaining = remainingTime - elapsed;

        if (remaining <= 0) {
          clearInterval(interval);
          setHoverMessage(''); // Clear hover message when time is up
        } else if (isHovering) {
          const minutesLeft = Math.floor(remaining / 60000);
          const secondsLeft = Math.floor((remaining % 60000) / 1000);
          setHoverMessage(`Please wait ${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft} before you try again`);
        }
      }, 1000);

      // Cleanup interval on hover state change or component unmount
      return () => clearInterval(interval);
    } else {
      setHoverMessage(''); // Clear the message when not hovering
    }
  }, [isHovering, isEmailButtonDisabled]);

  useEffect(() => {
    const handleDialogClick = (event) => {
      if (dialogContentRef.current && yubikeyInputRef.current && !yubikeyInputRef.current.contains(event.target)) {
        yubikeyInputRef.current.focus(); // Refocus the input field when clicking outside of it
      }
    };

    if (openYubikeyDialog) {
      // Set focus when the dialog opens
      if (yubikeyInputRef.current) {
        yubikeyInputRef.current.focus();
      }

      // Add click event listener to the dialog content
      document.addEventListener('click', handleDialogClick);
    }

    // Cleanup event listener when the dialog is closed
    return () => {
      document.removeEventListener('click', handleDialogClick);
    };
  }, [openYubikeyDialog]);

  const handleBack = () => {
    navigate(-1);
  };

  const validationsRegister = yup.object().shape({
    firstname: yup
      .string()
      .matches(/^[A-Za-z]+$/, "First name should only contain alphabets")
      .required("First name is mandatory"),
    lastname: yup
      .string()
      .matches(/^[A-Za-z]+$/, "Last name should only contain alphabets")
      .required("Last name is mandatory"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is mandatory"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[^\w]/, 'Password requires a symbol')
      .required("Password is mandatory"),
    confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "The passwords are different")
      .required("Password confirmation is mandatory"),
  });

  return (
    <div>
      <div>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" />
        <button className='register-back-botton' onClick={handleBack}>BACK</button>
      </div>
      <hr className='register-breakline' />
      <div className='register-card'>
        <h1 className='register'>Register New User</h1>
        <Formik
          initialValues={{
            id: '',
            verificationCode: '',
            firstname: '',
            lastname: '',
            companyname: '',
            email: '',
            password: '',
            confirmation: '',
          }}
          onSubmit={handleRegister}
          validationSchema={validationsRegister}
        >
          {({ values }) => (
            <Form className="register-form">
              <div className="register-form-group">
                <Field name="id" type='text' className="register-form-id-field" placeholder="ID" disabled={isIdLocked} />
                <ErrorMessage component="span" name="id" className="register-form-error" />
              </div>

              <div className="register-form-group">
                <Field name="verificationCode" type='password' className="register-form-verificationcode-field" placeholder="Verification code" disabled={isIdLocked} />
                <ErrorMessage component="span" name="verificationCode" className="register-form-error" />
                <button className="register-form-verify-button" type="button" onClick={() => handleVerifyId(values)} disabled={isVerifyDisabled}>VERIFY</button>
              </div>

              <div className="register-form-group">
                <Field name="firstname" type='text' className="register-form-firstname-field" placeholder="First name" />
                <ErrorMessage component="span" name="firstname" className="register-form-error" />
              </div>

              <div className="register-form-group">
                <Field name="lastname" type='text' className="register-form-lastname-field" placeholder="Last name" />
                <ErrorMessage component="span" name="lastname" className="register-form-error" />
              </div>

              <div className="register-form-group">
                <Field name="email" type="text" className="register-form-email-field" placeholder="Email" disabled={isEmailButtonDisabled} />
                <ErrorMessage component="span" name="email" className="register-form-error" />

                <button
                  className="register-form-verify-button"
                  type="button"
                  onClick={() => sendEmail(values)}
                  disabled={isEmailButtonDisabled}
                  onMouseOver={() => {
                    setIsHovering(true); // Set hovering to true when mouse is over the button
                  }}
                  onMouseOut={() => {
                    setIsHovering(false); // Set hovering to false when mouse leaves the button
                    setHoverMessage(''); // Clear hover message when mouse moves out
                  }}
                >
                  SEND EMAIL
                </button>

                {hoverMessage && <div className="hover-message">{hoverMessage}</div>} {/* Show hoverMessage below button */}
              </div>

              <div className="register-form-group">
                <Field name="emailcode" type='password' className="register-form-emailcode-field" placeholder="Email verification code" />
                <ErrorMessage component="span" name="emailcode" className="register-form-error" />
              </div>

              <div className="register-form-group">
                <Field name="companyname" type='text' className="register-form-companyname-field" placeholder="Company name" />
              </div>

              <div>
                <select className="form-select country" aria-label="Country select" onChange={loadStates}>
                  <option selected>Select Country</option>
                </select>
                <select className="form-select state" aria-label="State select" onChange={loadCities} disabled={stateSelectDisabled}>
                  <option selected>Select State</option>
                </select>
                <select className="form-select city" aria-label="City select" disabled={citySelectDisabled}>
                  <option selected>Select City</option>
                </select>
              </div>

              <div className="register-form-group">
                <Field name="password" type='password' className="register-form-password-field" placeholder="Password" />
                <ErrorMessage component="span" name="password" className="register-form-error" />
              </div>

              <div className="register-form-group">
                <Field name="confirmation" type='password' className="register-form-password-field" placeholder="Confirm your password" />
                <ErrorMessage component="span" name="confirmation" className="register-form-error" />
              </div>

              <button className="register-yubikey-button" type="submit" >
                Register YubiKey
              </button>

              <Dialog
                open={openYubikeyDialog}
                onClose={handleCloseYubikeyDialog}
              >
                <DialogTitle>YubiKey Registration</DialogTitle>
                <DialogContent ref={dialogContentRef}>
                  <p>Please insert your YubiKey, touch it, and then verify.</p>
                  <input
                    type="text"
                    ref={yubikeyInputRef} // Attach the ref to the input field
                    className="yubikeycode-field-hidden"
                    placeholder="Enter YubiKey Code"
                    value={yubikeyCode}
                    onChange={(e) => setYubikeyCode(e.target.value)}
                    autoFocus
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseYubikeyDialog} color="secondary">Cancel</Button>
                  <Button onClick={() => handleRegisterYubikey(values)} color="primary" disabled={!yubikeyCode}>
                    Register
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      </div>

      <ToastContainer position='top-right' />
    </div>
  );
}

export default Register;
