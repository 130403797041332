import React, { useState, useEffect } from "react";
import "../Styles/LogicFunctionContent.css";
import {
  Dropdown,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  Panel,
  PanelType,
  Toggle,
  SpinButton,
  TextField
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Axios from "axios";


const LogicFunctionContent = ({ panel }) => {
  const GenerateDeviceAddressName = (loop, address, isModule) => {
    const addr = address < 10 ? "00" + address.toString() : address < 100 ? "0" + address.toString() : address.toString();
    return isModule ? `L${loop.toString()}M${addr}` : `L${loop.toString()}D${addr}`;
  }

  const GenerateTwoDigitalsNum = (num) => {
    if (num < 10) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }

  const GenerateThreeDigitalsNum = (num) => {
    if (num < 10) {
      return "00" + num.toString();
    } else if (num < 100) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }

  const GenerateElementNameFromConfigFile = (operand) => {
    let elementName = "";
    let eventName = "";
    if (operand > 16777215) {
      let num = operand / 16777216;
      elementName = elementName + "Pn" + GenerateTwoDigitalsNum(num);
      operand %= 16777216;
    }
    if (operand > 65535) {
      let event = operand / 65536;
      switch (event) {
        case 1:
          eventName = "A";
          break;
        case 2:
          eventName = "P";
          break;
        case 3:
          eventName = "F";
          break;
        case 4:
          eventName = "E";
          break;
        case 5:
          eventName = "T";
          break;
        default:
          break;
      }
      operand %= 65535;
    }
    if (operand <= 2160) {
      let loopIndex = (operand - 1) / 240;
      let deviceIndex = (operand - 1) % 240;
      if (panel.VT5000_CONFIG.ArgusTablePr[loopIndex][deviceIndex].lastTipo === 3) {
        elementName = elementName + "L" + GenerateTwoDigitalsNum(loopIndex + 1) + "M" + GenerateThreeDigitalsNum(deviceIndex + 1);
      } else {
        elementName = elementName + "L" + GenerateTwoDigitalsNum(loopIndex + 1) + "D" + GenerateThreeDigitalsNum(deviceIndex + 1);
      }
    } else if (operand >= 3841 && operand <= 3849) {
      // Need fix in the future
    } else if (operand >= 3857 && operand <= 4048) {
      elementName = elementName + "Z" + GenerateThreeDigitalsNum(operand - 3856);
    } else if (operand >= 4241 && operand <= 4432) {
      elementName = elementName + "Lf" + GenerateThreeDigitalsNum(operand - 4240);
    } else if (operand >= 4625) {
      // Need fix in the future
    } else {
      return "";
    }
    elementName = elementName + eventName;
    return elementName;
  }

  const GenerateElementNameFromAddress = (eventType) => {
    let netAddressString = netAddress < 10 ? "0" + netAddress.toString() : netAddress.toString();
    let elementAddress = netAddress > 0 ? "Pn" + netAddressString + elementCode + eventType : elementCode + eventType
    return c2Index === 3 ? elementAddress + ")" : elementAddress;
  }

  const GenerateDetectorCodeFromAddress = (netAddress, loopIndex, deviceIndex, eventType) => {
    let event = 0;
    switch (eventType) {
      case "A":
        event = 1 * 65536;
        break;
      case "F":
        event = 3 * 65536;
        break;
      case "E":
        event = 4 * 65536;
        break;
    }
    return 1 + loopIndex * 240 + deviceIndex + netAddress * 16777216 + event;
  }

  const GenerateModuleCodeFromAddress = (netAddress, loopIndex, deviceIndex, eventType) => {
    let event = 0;
    switch (eventType) {
      case "A":
        event = 1 * 65536;
        break;
      case "P":
        event = 2 * 65536;
        break;
      case "F":
        event = 3 * 65536;
        break;
      case "E":
        event = 4 * 65536;
        break;
      case "T":
        event = 5 * 65536;
        break;
    }
    return 1 + loopIndex * 240 + deviceIndex + netAddress * 16777216 + event;
  }

  const [selectedLoop, setSelectedLoop] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedLogicFunction, setSelectedLogicFunction] = useState(null); // Store selected device index
  const [selectedZone, setSelectedZone] = useState(null); // need delete
  const [isDevicesDialogOpen, { setTrue: openDevicesDialog, setFalse: dismissDevicesDialog }] = useBoolean(false); // need delete
  const [isZonesDialogOpen, { setTrue: openZonesDialog, setFalse: dismissZonesDialog }] = useBoolean(false);
  const [isLogicFunctionsDialogOpen, { setTrue: openLogicFunctionsDialog, setFalse: dismissLogicFunctionsDialog }] = useBoolean(false);
  const [isVTNetsDialogOpen, { setTrue: openVTNetsDialog, setFalse: dismissVTNetsDialog }] = useBoolean(false);
  const [isOutputZonesDialogOpen, { setTrue: openOutputZonesDialog, setFalse: dismissOutputZonesDialog }] = useBoolean(false);
  const [logicContent, setLogicContent] = useState(null);
  const [c1Index, setC1Index] = useState(0);
  const [c2Index, setC2Index] = useState(0);
  const [delay, setDelay] = useState(0);
  const [duration, setDuration] = useState(0);
  const [outputZones, setOutputZones] = useState(Array(4).fill(0));
  const [logicExpression, setLogicExpression] = useState("(");
  const [logicExpressionList, setLogicExpressionList] = useState(Array(1).fill("("));
  const [isLogicFunctionDialogOpen, { setTrue: openLogicFunctionDialog, setFalse: dismissLogicFunctionDialog }] = useBoolean(false);
  const [isWeeklyTrendDialogOpen, { setTrue: openWeeklyTrendDialog, setFalse: dismissWeeklyTrendDialog }] = useBoolean(false);
  const [netAddress, setNetAddress] = useState(0);
  const [elementCode, setElementCode] = useState("");
  const [isDetectorEventDialogOpen, { setTrue: openDetectorEventDialog, setFalse: dismissDetectorEventDialog }] = useBoolean(false);
  const [isModuleEventDialogOpen, { setTrue: openModuleEventDialog, setFalse: dismissModuleEventDialog }] = useBoolean(false);
  const [isShowOperand, { setTrue: showOperand, setFalse: hideOperand }] = useBoolean(true);
  const [zoneName, setZoneName] = useState("");
  const [weeklyTrend, setWeeklyTrend] = useState(0);
  const [isAllSystem, setIsAllSystem] = useState(false);
  const [alarm, setAlarm] = useState(false);
  const [alarmCoincidence, setAlarmCoincidence] = useState(0);
  const [fault, setFault] = useState(false);
  const [faultCoincidence, setFaultCoincidence] = useState(0);
  const [technical, setTechnical] = useState(false);
  const [technicalCoincidence, setTechnicalCoincidence] = useState(0);
  const [loop, setLoop] = useState(0);
  const [devices, setDevices] = useState(Array(0).fill(""));

  useEffect(() => {
    if (selectedLogicFunction !== null) {
      setLogicContent({
        c1Level: [
          {
            c1Operat: 5,
            c2Operand: [0, 0, 0, 0],
            c2Operator: [5, 0, 0, 0]
          },
          {
            c1Operat: 0,
            c2Operand: [0, 0, 0, 0],
            c2Operator: [0, 0, 0, 0]
          },
          {
            c1Operat: 0,
            c2Operand: [0, 0, 0, 0],
            c2Operator: [0, 0, 0, 0]
          },
          {
            c1Operat: 0,
            c2Operand: [0, 0, 0, 0],
            c2Operator: [0, 0, 0, 0]
          }
        ]
      });
      setC1Index(0);
      setC2Index(0);
      showOperand();
      let outputZonesData = panel?.VT5000_CONFIG?.FlogicPr?.[selectedLogicFunction]?.outputZones || Array(4).fill(0);
      setOutputZones(outputZonesData);
    }
  }, [selectedLogicFunction, panel]);

  if (!panel || Object.keys(panel).length === 0) {
    return <div>No panel data found.</div>;
  }

  const loopCardNb = panel.VT5000_CONFIG.GeneralParameters?.loopCardNb || 0;
  const loopButtonsCount = 1 + 2 * loopCardNb;

  const handleIsSelected = (loopIndex, deviceIndex) => {
    if (selectedLogicFunction === null) {
      return false;
    }
    const devices = panel.VT5000_CONFIG.OutputZones[selectedLogicFunction].devices;
    if ((loopIndex + 1 === devices[0].loop) && (deviceIndex + 1 === devices[0].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[1].loop) && (deviceIndex + 1 === devices[1].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[2].loop) && (deviceIndex + 1 === devices[2].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[3].loop) && (deviceIndex + 1 === devices[3].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[4].loop) && (deviceIndex + 1 === devices[4].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[5].loop) && (deviceIndex + 1 === devices[5].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[6].loop) && (deviceIndex + 1 === devices[6].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[7].loop) && (deviceIndex + 1 === devices[7].address)) {
      return true;
    }
  };

  const handleLogicFunctionButtonClick = (index) => {
    setSelectedLogicFunction(index);
    openLogicFunctionDialog();
  }

  const handleDeviceButtonClick = (loopIndex, deviceIndex) => {
    const device = panel.VT5000_CONFIG.ArgusTablePr[loopIndex][deviceIndex];
    const isModule = device.programmed === 1 && device.deviceType === 1;
    const deviceName = GenerateDeviceAddressName(loopIndex + 1, deviceIndex + 1, isModule);
    setElementCode(deviceName);
    isModule ? openModuleEventDialog() : openDetectorEventDialog();
  };

  const handleDetectorAlarmButtonClick = (eventType) => {
    const elementName = GenerateElementNameFromAddress(eventType);
    let newLogicExpressionList = [...logicExpressionList];
    let newLogicExpression = logicExpression + elementName;
    newLogicExpressionList.push(elementName);
    setLogicExpression(newLogicExpression);
    setLogicExpressionList(newLogicExpressionList);
    const code = GenerateDetectorCodeFromAddress(netAddress, selectedLoop, selectedDevice, "A");
    setLogicContent((prev) => ({
      ...prev,
      c1Level: prev.c1Level.map((level, index) =>
        index === c1Index
          ? {
              ...level,
              c2Operand: level.c2Operand.map((op, i) =>
                i === c2Index ? code : op
              ),
            }
          : level
      ),
    }));
    // Need c1Index
    hideOperand();
    dismissDetectorEventDialog();
    dismissDevicesDialog();
  };

  const handleModuleAlarmButtonClick = (eventType) => {
    const elementName = GenerateElementNameFromAddress(eventType);
    let newLogicExpressionList = [...logicExpressionList];
    let newLogicExpression = logicExpression + elementName;
    newLogicExpressionList.push(elementName);
    setLogicExpression(newLogicExpression);
    setLogicExpressionList(newLogicExpressionList);
    const code = GenerateDetectorCodeFromAddress(netAddress, selectedLoop, selectedDevice, "A");
    setLogicContent((prev) => ({
      ...prev,
      c1Level: prev.c1Level.map((level, index) =>
        index === c1Index
          ? {
              ...level,
              c2Operand: level.c2Operand.map((op, i) =>
                i === c2Index ? code : op
              ),
            }
          : level
      ),
    }));
    hideOperand();
    dismissModuleEventDialog();
    dismissDevicesDialog();
  };

  const handleEndOperatorClick = () => {
    if (c2Index < 3 && c1Index < 3) {
      setLogicContent((prev) => ({
        ...prev,
        c1Level: prev.c1Level.map((level, index) =>
          index === c1Index
            ? { ...level, c2Operator: level.c2Operator.map((op, i) => (c2Index + 1 === i ? 6 : op)) }
            : level
        ),
      }));
      let newLogicExpressionList = [...logicExpressionList];
      let newLogicExpression = logicExpression + ")";
      newLogicExpressionList.push(")");
      setLogicExpression(newLogicExpression);
      setLogicExpressionList(newLogicExpressionList);
      setC1Index(c1Index + 1);
      setC2Index(0);
      // if (c1Index <= 3) {
      //   setLogicContent((prev) => ({
      //     ...prev,
      //     c1Level: prev.c1Level.map((level, index) =>
      //       index === c1Index ? { ...level, c1Operat: 5 } : level
      //     ),
      //   }));
      // }
    }
  };

  const handleAndOperatorClick = () => {
    if (c2Index === 0) {
      if (c1Index <= 3) {
        if (logicContent.c1Level[c1Index].c2Operator[c2Index] === 0) {
          setLogicContent((prev) => ({
            ...prev,
            c1Level: prev.c1Level.map((level, index) =>
              index === c1Index ? { ...level, c1Operat: 1 } : level
            ),
          }));
          let newLogicExpressionList = [...logicExpressionList];
          let newLogicExpression = logicExpression + " & (";
          newLogicExpressionList.push(" & (");
          setLogicExpression(newLogicExpression);
          setLogicExpressionList(newLogicExpressionList);
          setLogicContent((prev) => ({
            ...prev,
            c1Level: prev.c1Level.map((level, index) =>
              index === c1Index
                ? {
                    ...level,
                    c2Operator: level.c2Operator.map((op, i) =>
                      c2Index === i ? 5 : op
                    ),
                  }
                : level
            ),
          }));
        } else {
          hideOperand(); // 👈 first
          setTimeout(() => {
            setLogicContent((prev) => ({
              ...prev,
              c1Level: prev.c1Level.map((level, index) =>
                index === c1Index
                  ? {
                      ...level,
                      c2Operator: level.c2Operator.map((op, i) =>
                        c2Index + 1 === i ? 1 : op
                      ),
                    }
                  : level
              ),
            }));
            let newLogicExpressionList = [...logicExpressionList];
            let newLogicExpression = logicExpression + " & ";
            newLogicExpressionList.push(" & ");
            setLogicExpression(newLogicExpression);
            setLogicExpressionList(newLogicExpressionList);
            setC2Index(c2Index + 1);
          }, 0); // 👈 delay state updates just one tick
        }
      }
    } else {
      hideOperand();
      setTimeout(() => {
        setLogicContent((prev) => ({
          ...prev,
          c1Level: prev.c1Level.map((level, index) =>
            index === c1Index
              ? {
                  ...level,
                  c2Operator: level.c2Operator.map((op, i) =>
                    c2Index + 1 === i ? 1 : op
                  ),
                }
              : level
          ),
        }));
        let newLogicExpressionList = [...logicExpressionList];
        let newLogicExpression = logicExpression + " & ";
        newLogicExpressionList.push(" & ");
        setLogicExpression(newLogicExpression);
        setLogicExpressionList(newLogicExpressionList);
        setC2Index(c2Index + 1);
      }, 0);
    }
  };
  



  const onSave = () => {
    if (selectedLogicFunction >= 0) {
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].loop = loop;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].name = zoneName;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].alarm = alarm ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].alarmCoincidence = alarm ? alarmCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].fault = fault ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].faultCoincidence = fault ? faultCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].technical = technical ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].technicalCoincidence = technical ? technicalCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].allSystem = isAllSystem ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].programmed = 1;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].weeklyTrendDisablement = weeklyTrend;
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_zones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      zones: panel.VT5000_CONFIG.ZoneTable,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("Zones updated successfully");
          dismissDevicesDialog();
        } else {
          console.error("Failed to update Zones");

        }
      })
      .catch((error) => {
        console.error("Error updating Zones:", error);
      });
  };

  const onRemove = () => {
    if (selectedLogicFunction >= 0) {
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].loop = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].name = "";
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].alarm = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].alarmCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].fault = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].faultCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].technical = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].technicalCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].allSystem = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].programmed = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedLogicFunction].weeklyTrendDisablement = 0;
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_zones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      zones: panel.VT5000_CONFIG.ZoneTable,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("Zones updated successfully");
          dismissDevicesDialog();
        } else {
          console.error("Failed to update Zones");

        }
      })
      .catch((error) => {
        console.error("Error updating Zones:", error);
      });
  };

  return (
    <div className="logicFunction-content">
      {/* Logic Functions Buttons */}
      <div className="logicFunction-buttons-container">
        <div className="logicFunction-buttons-frame">
          {Array.from({ length: panel.VT5000_CONFIG.FlogicPr.length }, (_, index) => {
            const i = index; // Zone index
            const logicFunction = panel.VT5000_CONFIG.FlogicPr?.[i];
            const isProgrammed = logicFunction?.programmed === 1;

            let buttonClass = isProgrammed ? "programmed" : "not-programmed"; // Default class

            return (
              <button
                key={index}
                className={`logicFunction-button ${buttonClass}`}
                onClick={() => handleLogicFunctionButtonClick(index)}
              >
                <span>{index + 1}</span>
              </button>
            );
          })}
        </div>
      </div>

      <Dialog
        hidden={!isLogicFunctionDialogOpen}
        onDismiss={dismissLogicFunctionDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: `Logic Function ${selectedLogicFunction + 1}`
        }}
        minWidth={"1000px"}
      >
        <div>
          <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
            <TextField multiline autoAdjustHeight value={logicExpression} readOnly />
          </div>
          {isShowOperand && (<div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
            <button onClick={() => openDevicesDialog()}>Devices</button>
            <button onClick={() => console.log("Zones Dialog Open")}>Zones</button>
            <button onClick={() => console.log("OlyNet Dialog Open")}>OlyNet</button>
            <button onClick={() => console.log("Logic Function Dialog Open")}>Logic Function</button>
          </div>)}
          {!isShowOperand && (<div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
            <button onClick={() => handleEndOperatorClick()}>)</button>
            <button onClick={() => handleAndOperatorClick()}>And (&)</button>
            <button onClick={() => console.log("OlyNet Dialog Open")}>Or (|)</button>
            <button onClick={() => console.log("Logic Function Dialog Open")}>Xor (^)</button>
            <button onClick={() => console.log("Logic Function Dialog Open")}>Not (!)</button>
          </div>)}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Delay:</span>
                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                  <SpinButton
                    min={0}
                    max={255}
                    value={delay.toString()} // Ensure the value is a string
                    onChange={(event, newValue) => {
                      const parsedValue = parseInt(newValue, 10);
                      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 255) {
                        setDelay(parsedValue);
                      }
                    }}
                    styles={{
                      root: { marginBottom: "0px", padding: "0px", width: "100%" },
                      spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                      input: { textAlign: "center", fontSize: "14px" },
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Duration:</span>
                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                  <SpinButton
                    min={0}
                    max={255}
                    value={duration.toString()} // Ensure the value is a string
                    onChange={(event, newValue) => {
                      const parsedValue = parseInt(newValue, 10);
                      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 255) {
                        setDuration(parsedValue);
                      }
                    }}
                    styles={{
                      root: { marginBottom: "0px", padding: "0px", width: "100%" },
                      spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                      input: { textAlign: "center", fontSize: "14px" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
            {outputZones.map((value, index) => (
              <button key={index}>{value}</button>
            ))}
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
            <PrimaryButton text="Save" />
            <DefaultButton text="Delete" />
            <DefaultButton text="Back" onClick={dismissLogicFunctionDialog} />
          </div>
        </div>
      </Dialog>

      <Dialog
        hidden={!isDevicesDialogOpen}
        onDismiss={dismissDevicesDialog}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        minWidth={"1000px"}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          </div>
          <div className="devices-content">
            {/* Loop Buttons */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
              <div className="loop-buttons">
                <span>Loop:</span>
                {Array.from({ length: loopButtonsCount }, (_, index) => (
                  <button
                    key={index}
                    className={`loop-button ${selectedLoop === index ? "selected-loop" : ""}`}
                    onClick={() => setSelectedLoop(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>

            {/* Device Buttons */}
            <div className="device-buttons-container">
              <div className="device-buttons-frame">
                {Array.from({ length: panel.VT5000_CONFIG.ArgusTablePr[0].length }, (_, index) => {
                  const i = selectedLoop; // Loop index
                  const j = index; // Device index
                  const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];
                  const isProgrammed = device?.programmed === 1;
                  const isModule = isProgrammed && (device.deviceType === 1);
                  const isDevice = isProgrammed && (device.deviceType === 0);
                  const isSmoke = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 0);
                  const isThermal = isDevice && (device.lastTipo === 1);
                  const isSmokeTherm = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 1);
                  const currentZone = panel.VT5000_CONFIG.ZoneTable[selectedZone];
                  const isDisabled = !(isSmoke || isThermal || isSmokeTherm || (isModule && (device.lastTipo === 3))) || isAllSystem || loop === selectedLoop + 1 || (currentZone ? (device.zone > 0 && device.zone - 1 !== selectedZone) : false);
                  const isSelected = (isSmoke || isThermal || isSmokeTherm || (isModule && (device.lastTipo === 3))) && (isAllSystem || loop === selectedLoop + 1 || devices.includes(GenerateDeviceAddressName(i + 1, j + 1)));

                  let buttonClass = "not-programmed"; // Default class
                  if (isModule) {
                    buttonClass = "module";
                  }
                  else if (isSmoke) {
                    buttonClass = "smoke";
                  }
                  else if (isThermal) {
                    buttonClass = "heat";
                  }
                  else if (isSmokeTherm) {
                    buttonClass = "smokeTherm";
                  }

                  return (
                    <button
                      key={index}
                      className={`device-button ${buttonClass} ${isSelected ? "selected" : ""}`}
                      disabled={isDisabled}
                      onClick={() => { handleDeviceButtonClick(i, j); setSelectedDevice(j) }}
                    >
                      <span>{index + 1}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        hidden={!isDetectorEventDialogOpen}
        onDismiss={dismissDetectorEventDialog}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        minWidth={"1000px"}
      >
        Sensor Event 1
        <button onClick={() => handleDetectorAlarmButtonClick("A")}>Alarm(A)</button>
        <button onClick={() => handleDetectorAlarmButtonClick("F")}>Fault(F)</button>
        <button onClick={() => handleDetectorAlarmButtonClick("E")}>Exclusion(E)</button>
      </Dialog>

      <Dialog
        hidden={!isModuleEventDialogOpen}
        onDismiss={dismissModuleEventDialog}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        minWidth={"1000px"}
      >
        Module Event 1
        <button onClick={() => handleModuleAlarmButtonClick("A")}>Alarm(A)</button>
        <button onClick={() => handleModuleAlarmButtonClick("P")}>Prealarm(P)</button>
        <button onClick={() => handleModuleAlarmButtonClick("F")}>Fault(F)</button>
        <button onClick={() => handleModuleAlarmButtonClick("E")}>Exclusion(E)</button>
        <button onClick={() => handleModuleAlarmButtonClick("T")}>Technological(T)</button>
      </Dialog>
    </div>
  );
};

export default LogicFunctionContent;
