import React, { useState, useEffect } from "react";
import "../Styles/ZonesContent.css";
import {
  Dropdown,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  Panel,
  PanelType,
  Toggle,
  SpinButton
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Axios from "axios";


const ZonesContent = ({ panel }) => {
  const GenerateDeviceAddressName = (loop, address) => {
    const addr = address < 10 ? "00" + address.toString() : address < 100 ? "0" + address.toString() : address.toString();
    return `L${loop.toString()}M${addr}`
  }

  const [selectedLoop, setSelectedLoop] = useState(0);
  const [selectedZone, setSelectedZone] = useState(null); // Store selected device index
  const [isDevicesDialogOpen, { setTrue: openDevicesDialog, setFalse: dismissDevicesDialog }] = useBoolean(false);
  const [isWeeklyTrendDialogOpen, { setTrue: openWeeklyTrendDialog, setFalse: dismissWeeklyTrendDialog }] = useBoolean(false);
  const [zoneName, setZoneName] = useState("");
  const [weeklyTrend, setWeeklyTrend] = useState(0);
  const [isAllSystem, setIsAllSystem] = useState(false);
  const [alarm, setAlarm] = useState(false);
  const [alarmCoincidence, setAlarmCoincidence] = useState(0);
  const [fault, setFault] = useState(false);
  const [faultCoincidence, setFaultCoincidence] = useState(0);
  const [technical, setTechnical] = useState(false);
  const [technicalCoincidence, setTechnicalCoincidence] = useState(0);
  const [loop, setLoop] = useState(0);
  const [devices, setDevices] = useState(Array(0).fill(""));

  if (!panel || Object.keys(panel).length === 0) {
    return <div>No panel data found.</div>;
  }

  const loopCardNb = panel.VT5000_CONFIG.GeneralParameters?.loopCardNb || 0;
  const loopButtonsCount = 1 + 2 * loopCardNb;

  const handleIsSelected = (loopIndex, deviceIndex) => {
    if (selectedZone === null) {
      return false;
    }
    const devices = panel.VT5000_CONFIG.OutputZones[selectedZone].devices;
    if ((loopIndex + 1 === devices[0].loop) && (deviceIndex + 1 === devices[0].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[1].loop) && (deviceIndex + 1 === devices[1].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[2].loop) && (deviceIndex + 1 === devices[2].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[3].loop) && (deviceIndex + 1 === devices[3].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[4].loop) && (deviceIndex + 1 === devices[4].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[5].loop) && (deviceIndex + 1 === devices[5].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[6].loop) && (deviceIndex + 1 === devices[6].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[7].loop) && (deviceIndex + 1 === devices[7].address)) {
      return true;
    }
  };

  const handleZoneButtonClick = (index) => {
    setSelectedZone(index);
    setSelectedLoop(0);
    setZoneName(panel.VT5000_CONFIG.ZoneTable[index].name);
    setWeeklyTrend(panel.VT5000_CONFIG.ZoneTable[index].weeklyTrendDisablement);
    setIsAllSystem(panel.VT5000_CONFIG.ZoneTable[index].allSystem === 1);
    setAlarm(panel.VT5000_CONFIG.ZoneTable[index].alarm === 1);
    setAlarmCoincidence(panel.VT5000_CONFIG.ZoneTable[index].alarmCoincidence);
    setFault(panel.VT5000_CONFIG.ZoneTable[index].fault === 1);
    setFaultCoincidence(panel.VT5000_CONFIG.ZoneTable[index].faultCoincidence);
    setTechnical(panel.VT5000_CONFIG.ZoneTable[index].technical);
    setTechnicalCoincidence(panel.VT5000_CONFIG.ZoneTable[index].technicalCoincidence);
    setDevices(Array(0).fill(""));
    if (!isAllSystem && loop === 0) {
      for (let i = 0; i < panel.VT5000_CONFIG.ArgusTablePr.length; i++) {
        for (let j = 0; j < panel.VT5000_CONFIG.ArgusTablePr[i].length; j++) {
          if (panel.VT5000_CONFIG.ArgusTablePr[i][j].zone === selectedLoop + 1) {
            setDevices((prevValues) => {
              const newValues = [...prevValues];
              newValues.push(GenerateDeviceAddressName(i + 1, j + 1));
              return newValues;
            });
          }
        }
      }
    }
    openDevicesDialog();
  };

  const handleDeviceButtonClick = (loopIndex, deviceIndex) => {
    const deviceName = GenerateDeviceAddressName(loopIndex + 1, deviceIndex + 1);
    setDevices((prevValues) => {
      const newValues = [...prevValues];
      const index = newValues.indexOf(deviceName);
      if (index !== -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(deviceName);
      }
      return newValues;
    })
  };

  const handleLoopSelected = () => {
    if (loop > 0) {
      setLoop(0);
    } else {
      setIsAllSystem(false);
      setLoop(selectedLoop + 1);
    }
  }

  const handleWeeklyTrendBlockClick = (index) => {
    setWeeklyTrend(index + 1);
    dismissWeeklyTrendDialog();
  };

  const onSave = () => {
    if (selectedZone >= 0) {
      panel.VT5000_CONFIG.ZoneTable[selectedZone].loop = loop;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].name = zoneName;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].alarm = alarm ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].alarmCoincidence = alarm ? alarmCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].fault = fault ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].faultCoincidence = fault ? faultCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].technical = technical ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].technicalCoincidence = technical ? technicalCoincidence : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].allSystem = isAllSystem ? 1 : 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].programmed = 1;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].weeklyTrendDisablement = weeklyTrend;
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_zones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      zones: panel.VT5000_CONFIG.ZoneTable,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("Zones updated successfully");
          dismissDevicesDialog();
        } else {
          console.error("Failed to update Zones");

        }
      })
      .catch((error) => {
        console.error("Error updating Zones:", error);
      });
  };

  const onRemove = () => {
    if (selectedZone >= 0) {
      panel.VT5000_CONFIG.ZoneTable[selectedZone].loop = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].name = "";
      panel.VT5000_CONFIG.ZoneTable[selectedZone].alarm = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].alarmCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].fault = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].faultCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].technical = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].technicalCoincidence = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].allSystem = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].programmed = 0;
      panel.VT5000_CONFIG.ZoneTable[selectedZone].weeklyTrendDisablement = 0;
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_zones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      zones: panel.VT5000_CONFIG.ZoneTable,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("Zones updated successfully");
          dismissDevicesDialog();
        } else {
          console.error("Failed to update Zones");

        }
      })
      .catch((error) => {
        console.error("Error updating Zones:", error);
      });
  };

  return (
    <div className="zone-content">
      {/* Zones Buttons */}
      <div className="zone-buttons-container">
        <div className="zone-buttons-frame">
          {Array.from({ length: panel.VT5000_CONFIG.ZoneTable.length }, (_, index) => {
            const i = index; // Zone index
            const outputZone = panel.VT5000_CONFIG.OutputZones?.[i];
            const isProgrammed = outputZone?.programmed === 1;

            let buttonClass = isProgrammed ? "programmed" : "not-programmed"; // Default class

            return (
              <button
                key={index}
                className={`zone-button ${buttonClass}`}
                onClick={() => handleZoneButtonClick(index)}
              >
                <span>{index + 1}</span>
              </button>
            );
          })}
        </div>
      </div>

      <Dialog
        hidden={!isDevicesDialogOpen}
        onDismiss={dismissDevicesDialog}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        minWidth={"1000px"}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left" }}>
              <label htmlFor="nameInput">Zone:</label>
              <input
                id="nameInput"
                type="text"
                value={zoneName}
                onChange={(e) => setZoneName(e.target.value)}
                placeholder={zoneName}
              />
              <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px" }}>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>All System:</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Toggle
                    styles={{
                      root: { marginBottom: "0px", padding: "0px" },
                      label: { display: "none" }, // Hide Fluent UI’s built-in label
                      pill: { marginTop: "0px", alignSelf: "center" },
                      thumb: { marginTop: "0px" },
                    }}
                    checked={isAllSystem}
                    onChange={() => {
                      setLoop(0);
                      setIsAllSystem(!isAllSystem)}}
                    onText="Yes"
                    offText="No"
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px" }}>
                <span style={{ fontSize: "16px" }}>Loop Selected:</span>
                <button
                  style={{ fontSize: "16px", background: "#E0E0E0", borderRadius: "5px", border: "1px solid black", height: "30px", width: "50px", textAlign: "center" }}
                  onClick={() => handleLoopSelected()}
                >
                  {loop}
                </button>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                <span style={{ fontSize: "16px" }}>Weekly Trend Autoexclusion:</span>
                <DefaultButton text={weeklyTrend} onClick={() => openWeeklyTrendDialog()} />
              </div>
              <Dialog
                hidden={!isWeeklyTrendDialogOpen}
                onDismiss={() => dismissWeeklyTrendDialog()}
                minWidth={"1000px"}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Weekly Trend",
                }}
              >
                <div className="weeklyTrend-buttons-container">
                  <div className="weeklyTrend-buttons-frame">
                    {Array.from({ length: panel.VT5000_CONFIG.WeeklyTrends.length }, (_, i) => {
                      const isProgrammed = panel.VT5000_CONFIG.WeeklyTrends[i]?.programmed === 1;
                      return (
                        <button
                          key={i}
                          className={`weeklyTrend-button ${isProgrammed ? "programmed" : "not-programmed"} ${weeklyTrend === i + 1 ? "selected" : ""
                            }`}
                          onClick={() => handleWeeklyTrendBlockClick(i)}
                          disabled={!isProgrammed}
                        >
                          <span>{i + 1}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </Dialog>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px", marginRight: "54px" }}>
                  <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Alarm:</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Toggle
                      styles={{
                        root: { marginBottom: "0px", padding: "0px" },
                        label: { display: "none" }, // Hide Fluent UI’s built-in label
                        pill: { marginTop: "0px", alignSelf: "center" },
                        thumb: { marginTop: "0px" },
                      }}
                      checked={alarm}
                      onChange={() => setAlarm(!alarm)}
                      onText="Yes"
                      offText="No"
                    />
                  </div>
                </div>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Alarm Coincidence:</span>
                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                  <SpinButton
                    min={0}
                    max={30}
                    value={alarmCoincidence.toString()} // Ensure the value is a string
                    disabled={!alarm}
                    onChange={(event, newValue) => {
                      const parsedValue = parseInt(newValue, 10);
                      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 30) {
                        setAlarmCoincidence(parsedValue);
                      }
                    }}
                    styles={{
                      root: { marginBottom: "0px", padding: "0px", width: "100%" },
                      spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                      input: { textAlign: "center", fontSize: "14px" },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px", marginRight: "62px" }}>
                  <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Fault:</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Toggle
                      styles={{
                        root: { marginBottom: "0px", padding: "0px" },
                        label: { display: "none" }, // Hide Fluent UI’s built-in label
                        pill: { marginTop: "0px", alignSelf: "center" },
                        thumb: { marginTop: "0px" },
                      }}
                      checked={fault}
                      onChange={() => setFault(!fault)}
                      onText="Yes"
                      offText="No"
                    />
                  </div>
                </div>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Fault Coincidence:</span>
                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                  <SpinButton
                    min={0}
                    max={30}
                    value={faultCoincidence.toString()} // Ensure the value is a string
                    disabled={!fault}
                    onChange={(event, newValue) => {
                      const parsedValue = parseInt(newValue, 10);
                      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 30) {
                        setFaultCoincidence(parsedValue);
                      }
                    }}
                    styles={{
                      root: { marginBottom: "0px", padding: "0px", width: "100%" },
                      spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                      input: { textAlign: "center", fontSize: "14px" },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px", height: "32px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px" }}>
                  <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Technological:</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Toggle
                      styles={{
                        root: { marginBottom: "0px", padding: "0px" },
                        label: { display: "none" }, // Hide Fluent UI’s built-in label
                        pill: { marginTop: "0px", alignSelf: "center" },
                        thumb: { marginTop: "0px" },
                      }}
                      checked={technical}
                      onChange={() => setTechnical(!technical)}
                      onText="Yes"
                      offText="No"
                    />
                  </div>
                </div>
                <span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Technological Coincidence:</span>
                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                  <SpinButton
                    min={0}
                    max={30}
                    value={technicalCoincidence.toString()} // Ensure the value is a string
                    disabled={!technical}
                    onChange={(event, newValue) => {
                      const parsedValue = parseInt(newValue, 10);
                      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 30) {
                        setTechnicalCoincidence(parsedValue);
                      }
                    }}
                    styles={{
                      root: { marginBottom: "0px", padding: "0px", width: "100%" },
                      spinButtonWrapper: { height: "32px", display: "flex", alignItems: "center" },
                      input: { textAlign: "center", fontSize: "14px" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="devices-content">
            {/* Loop Buttons */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
              <div className="loop-buttons">
                <span>Loop:</span>
                {Array.from({ length: loopButtonsCount }, (_, index) => (
                  <button
                    key={index}
                    className={`loop-button ${selectedLoop === index ? "selected-loop" : ""}`}
                    onClick={() => setSelectedLoop(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <PrimaryButton text="Save" onClick={() => onSave()} />
                <DefaultButton text="Remove" onClick={() => onRemove()} />
                <DefaultButton text="Back" onClick={dismissDevicesDialog} />
              </div>
            </div>

            {/* Device Buttons */}
            <div className="device-buttons-container">
              <div className="device-buttons-frame">
                {Array.from({ length: panel.VT5000_CONFIG.ArgusTablePr[0].length }, (_, index) => {
                  const i = selectedLoop; // Loop index
                  const j = index; // Device index
                  const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];
                  const isProgrammed = device?.programmed === 1;
                  const isModule = isProgrammed && (device.deviceType === 1);
                  const isDevice = isProgrammed && (device.deviceType === 0);
                  const isSmoke = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 0);
                  const isThermal = isDevice && (device.lastTipo === 1);
                  const isSmokeTherm = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 1);
                  const currentZone = panel.VT5000_CONFIG.ZoneTable[selectedZone];
                  const isDisabled = !(isSmoke || isThermal || isSmokeTherm || (isModule && (device.lastTipo === 3))) || isAllSystem || loop === selectedLoop + 1 || (currentZone ? (device.zone > 0 && device.zone - 1 !== selectedZone) : false);
                  const isSelected = (isSmoke || isThermal || isSmokeTherm || (isModule && (device.lastTipo === 3))) && (isAllSystem || loop === selectedLoop + 1 || devices.includes(GenerateDeviceAddressName(i + 1, j + 1)));

                  let buttonClass = "not-programmed"; // Default class
                  if (isModule) {
                    buttonClass = "module";
                  }
                  else if (isSmoke) {
                    buttonClass = "smoke";
                  }
                  else if (isThermal) {
                    buttonClass = "heat";
                  }
                  else if (isSmokeTherm) {
                    buttonClass = "smokeTherm";
                  }

                  return (
                    <button
                      key={index}
                      className={`device-button ${buttonClass} ${isSelected ? "selected" : ""}`}
                      disabled={isDisabled}
                      onClick={() => handleDeviceButtonClick(i, j)}
                    >
                      <span>{index + 1}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ZonesContent;
