import React, { useState, useEffect } from "react";
import { Toggle, Dropdown } from "@fluentui/react";
import Axios from "axios";
import { Icon } from "@fluentui/react/lib/Icon";

const VariousFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [silenceDuration, setSilenceDuration] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.silenceDuration || 0);
    const [earthFaultCheck, setEarthFaultCheck] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.earthFaultCheck !== 0);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.GeneralParameters) {
            panel.VT5000_CONFIG.GeneralParameters.silenceDuration = silenceDuration;
            panel.VT5000_CONFIG.GeneralParameters.earthFaultCheck = earthFaultCheck ? 1 : 0;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                generalparameters: panel.VT5000_CONFIG.GeneralParameters
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Loop settings updated successfully");
                    } else {
                        console.error("Failed to update loop settings");
                    }
                })
                .catch((error) => {
                    console.error("Error updating loop settings:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Various</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", width: "80%" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Silence Duration</span>
                    <Dropdown
                        placeholder="Select Silence Duration"
                        options={Array.from({ length: 256 }, (_, i) => ({ key: i, text: i.toString() }))}
                        selectedKey={silenceDuration}
                        onChange={(e, option) => setSilenceDuration(option.key)}
                        onRenderCaretDown={() => <Icon iconName="ChevronDown" styles={{ root: { fontSize: 20 } }} />} // Custom caret down icon
                        styles={{ dropdown: { width: 150 }, callout: { maxHeight: 200 } }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "100%", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "30px" }}>Ground Fault Control Enabled?</span>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", alignSelf: "center" }}>
                        <Toggle
                            checked={earthFaultCheck}
                            onChange={() => setEarthFaultCheck(!earthFaultCheck)}
                            styles={{ pill: { background: earthFaultCheck ? "#C00D0D" : "#FFF" }, root: { alignSelf: "center" } }}
                        />
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{earthFaultCheck ? "Yes" : "No"}</span>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("general")}>Back</button>
            </div>
        </div>
    );
};

export default VariousFrame;
