import { Buffer } from 'buffer';
import { VT5000_CONFIG } from './ParsePanelData';

// Define DeviceDescriptor and OutputZone if not imported
class DeviceDescriptor {
    constructor() {
        this.loop = 0;
        this.address = 0;
        this.active = 0;
    }
}

class OutputZone {
    constructor() {
        this.silenceable = 0; // Silenceable & 01, Activation Type
        this.programmed = 0;
        this.devices = Array(VT5000_CONFIG.MAX_OUT_PER_OUT_ZONE).fill(null).map(() => new DeviceDescriptor());
    }
}

class ConfigFileGenerator {
    constructor(config) {
        this.VT5000_CONFIG = config;
    }

    generateConfigFile() {
        const bufferArray = [];
        let buffer;

        // OutputZone serialization
        for (let i = 0; i < VT5000_CONFIG.MAX_OUTPUT_ZONES; i++) {
            const outputZone = this.VT5000_CONFIG.OutputZones[i];
            if (!outputZone) continue; // Ensure outputZone is defined
            
            let valueInt16 = (outputZone.silenceable & 0x01) | ((outputZone.programmed ? 1 : 0) << 6);
            buffer = Buffer.alloc(2);
            buffer.writeUInt16LE(valueInt16);
            bufferArray.push(buffer);

            for (let a = 0; a < VT5000_CONFIG.MAX_OUT_PER_OUT_ZONE; a++) {
                const device = outputZone.devices[a];
                if (!device) continue; // Ensure device is defined
                
                let deviceValue = (device.loop & 0x1f) | ((device.address & 0xff) << 5) | ((device.active & 0x01) << 1);
                buffer = Buffer.alloc(2);
                buffer.writeUInt16LE(deviceValue);
                bufferArray.push(buffer);
            }
        }

        // Zone_Table serialization
        for (let i = 0; i < VT5000_CONFIG.LZONE_NUM; i++) {
            const zone = this.VT5000_CONFIG.ZoneTable[i];
            if (!zone) continue; // Ensure zone is defined

            buffer = Buffer.alloc(VT5000_CONFIG.TEXT_LENGTH, 0); // Ensure buffer is properly initialized with zero-padding
            buffer.write(zone.name, 'utf8');
            bufferArray.push(buffer);

            // Write coincidence values
            bufferArray.push(Buffer.from([
                zone.alarmCoincidence,
                zone.preAlarmCoincidence,
                zone.faultCoincidence,
                zone.disablementCoincidence,
                zone.technicalCoincidence,
                zone.weeklyTrendDisablement,
                0 // Reserved byte
            ]));

            let valueInt16 = ((zone.alarm & 0x01) << 2) | ((zone.preAlarm & 0x01) << 3) |
                             ((zone.fault & 0x01) << 4) | ((zone.disablement & 0x01) << 5) |
                             ((zone.technical & 0x01) << 6) | ((zone.allSystem & 0x01) << 7) |
                             ((zone.loop & 0x1f) << 8) | ((zone.programmed & 0x01) << 14);
            buffer = Buffer.alloc(2);
            buffer.writeUInt16LE(valueInt16);
            bufferArray.push(buffer);

            for (let a = 0; a < VT5000_CONFIG.MAX_OUT_PER_AREA; a++) {
                const outputZone = zone.outputZone[a];
                if (!outputZone) continue; // Ensure outputZone is defined
                
                let outputValue = (outputZone.loop & 0x1f) | ((outputZone.address & 0xff) << 5) |
                                  ((outputZone.silenceable & 0x01) << 13) | ((outputZone.active & 0x01) << 14);
                buffer = Buffer.alloc(2);
                buffer.writeUInt16LE(outputValue);
                bufferArray.push(buffer);
            }
        }

        // GeneralParameters serialization
        const gp = this.VT5000_CONFIG.GeneralParameters;
        let valueInt32 = ((gp.password1 & 0x01) << 22) | ((gp.password2 & 0x01) << 23) | ((gp.password3 & 0x01) << 24) |
                         ((gp.psuDemoMode & 0x0f) << 25);
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(valueInt32);
        bufferArray.push(buffer);

        bufferArray.push(Buffer.from([gp.vtnetPanelAddress, gp.vtnetNetworkPanelNb, 0]));

        valueInt32 = (gp.vtnetAlarmVisible & 0x01) | ((gp.vtnetFaultVisible & 0x01) << 1) |
                     ((gp.vtnetResetEnable & 0x01) << 2) | ((gp.vtnetEvacEnable & 0x01) << 3);
        buffer = Buffer.alloc(1);
        buffer.writeUInt8(valueInt32);
        bufferArray.push(buffer);

        bufferArray.push(Buffer.from([gp.loopCardNb]));

        valueInt32 = (gp.loop2Closed & 0x01) | ((gp.loop3Closed & 0x01) << 1) | ((gp.loop4Closed & 0x01) << 2) |
                     ((gp.loop5Closed & 0x01) << 3) | ((gp.loop6Closed & 0x01) << 4) | ((gp.loop7Closed & 0x01) << 5) |
                     ((gp.loop8Closed & 0x01) << 6) | ((gp.loop9Closed & 0x01) << 7);
        buffer = Buffer.alloc(1);
        buffer.writeUInt8(valueInt32);
        bufferArray.push(buffer);

        buffer = Buffer.alloc(2);
        buffer.writeUInt16LE(gp.silenceDuration);
        bufferArray.push(buffer);

        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(gp.rs232BaudRate);
        bufferArray.push(buffer);
        bufferArray.push(Buffer.from([gp.panelLanguage]));

        buffer = Buffer.alloc(VT5000_CONFIG.PANEL_NAME_LENGTH, 0);
        buffer.write(gp.panelName, 'utf8');
        bufferArray.push(buffer);

        buffer = Buffer.alloc(VT5000_CONFIG.PANEL_NAME_LENGTH, 0);
        buffer.write(gp.assistanceName, 'utf8');
        bufferArray.push(buffer);

        bufferArray.push(Buffer.from([gp.panelColour]));

        buffer = Buffer.alloc(2);
        buffer.writeUInt16LE(gp.rs232Address);
        bufferArray.push(buffer);
        buffer = Buffer.alloc(2);
        buffer.writeUInt16LE(gp.rs232Dummy);
        bufferArray.push(buffer);

        bufferArray.push(Buffer.from([gp.panelLedBacklightColour, 0, 0, 0]));

        valueInt32 = ((gp.buzzerDisabled & 0x01) << 3) | ((gp.fireRelayDisablement & 0x01) << 7) |
                     ((gp.devicePollingLed & 0x01) << 9) | ((gp.loop1LedBlinkColour & 0x01) << 14) |
                     ((gp.earthFaultCheck & 0x01) << 15) | ((gp.loop1Closed & 0x01) << 16);
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(valueInt32);
        bufferArray.push(buffer);
        bufferArray.push(Buffer.from([0, 0, 0, 0]));

        // Codes serialization
        const codes = this.VT5000_CONFIG.Codes;
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(codes.engineer);
        bufferArray.push(buffer);
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(codes.op1);
        bufferArray.push(buffer);
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(codes.op2);
        bufferArray.push(buffer);
        buffer = Buffer.alloc(4);
        buffer.writeUInt32LE(codes.op3);
        bufferArray.push(buffer);

        // DaylightSaving serialization
        const daylightSaving = this.VT5000_CONFIG.DaylightSaving;
        bufferArray.push(Buffer.from([
            daylightSaving.savingTime.startDay,
            daylightSaving.savingTime.startMonth,
            daylightSaving.savingTime.endDay,
            daylightSaving.savingTime.endMonth,
            (daylightSaving.mode & 0x01) | ((daylightSaving.notProgrammed & 0x01) << 2)
        ]));

        // Holidays serialization
        for (let i = 0; i < VT5000_CONFIG.HOLIDAYS_NB; i++) {
            const holiday = this.VT5000_CONFIG.Holidays[i];
            bufferArray.push(Buffer.from([holiday.day, holiday.month, holiday.programmed & 0x01]));
        }

        // TimeIntervals serialization
        for (let i = 0; i < VT5000_CONFIG.TIME_INTERVALS; i++) {
            const interval = this.VT5000_CONFIG.TimeIntervals[i];
            for (let j = 0; j < 2; j++) {
                bufferArray.push(Buffer.from([
                    interval.fo[j].hourStart,
                    interval.fo[j].minuteStart,
                    interval.fo[j].hourEnd,
                    interval.fo[j].minuteEnd,
                    interval.fo[j].programmed & 0x01
                ]));
            }
        }

        // WeeklyTrends serialization
        for (let i = 0; i < VT5000_CONFIG.WEEKLY_TRENDS_NB; i++) {
            const trend = this.VT5000_CONFIG.WeeklyTrends[i];
            bufferArray.push(Buffer.from([ ...trend.days, trend.programmed & 0x01 ]));
        }

        // FlogicPr serialization
        for (let i = 0; i < VT5000_CONFIG.MAX_LOGIC_FUNC; i++) {
            const flogic = this.VT5000_CONFIG.FlogicPr[i];
            let valueInt32 = (flogic.programmed & 0x01) | ((flogic.fLogicAss & 0x01) << 1);
            buffer = Buffer.alloc(4);
            buffer.writeUInt32LE(valueInt32);
            bufferArray.push(buffer);

            for (let a = 0; a < VT5000_CONFIG.MAX_C1_LEVEL; a++) {
                buffer = Buffer.alloc(4);
                buffer.writeUInt32LE(flogic.c1Level[a].c1Operat & 0xFF);
                bufferArray.push(buffer);
                for (let b = 0; b < VT5000_CONFIG.MAX_C2_LEVEL; b++) {
                    buffer = Buffer.alloc(4);
                    buffer.writeUInt32LE(flogic.c1Level[a].c2Operand[b]);
                    bufferArray.push(buffer);
                    buffer = Buffer.alloc(4);
                    buffer.writeUInt32LE(flogic.c1Level[a].c2Operand[b] & 0xFF);
                    bufferArray.push(buffer);
                }
            }

            for (let a = 0; a < VT5000_CONFIG.MAX_C1_LEVEL; a++) {
                buffer = Buffer.alloc(1);
                buffer.writeUInt8(flogic.outputZones[a]);
                bufferArray.push(buffer);
            }

            buffer = Buffer.alloc(2);
            buffer.writeUInt16LE(flogic.delay);
            bufferArray.push(buffer);
            buffer = Buffer.alloc(2);
            buffer.writeUInt16LE(flogic.duration);
            bufferArray.push(buffer);
        }

        // ArgusTablePr serialization
        for (let i = 0; i < VT5000_CONFIG.LOOP_NUMBER_MAX; i++) {
            for (let j = 0; j < VT5000_CONFIG.MAX_ARGUS_DEVICES; j++) {
                const argus = this.VT5000_CONFIG.ArgusTablePr[i][j];
                let valueInt16 = (argus.deviceType & 0x01) | ((argus.sounderBase & 0x01) << 1);
                buffer = Buffer.alloc(2);
                buffer.writeUInt16LE(valueInt16);
                bufferArray.push(buffer);

                buffer = Buffer.alloc(VT5000_CONFIG.TEXT_LENGTH, 0);
                buffer.write(argus.name, 'utf8');
                bufferArray.push(buffer);

                let valueInt32 = (argus.alarmVerification & 0x01) | ((argus.alarmVerificationDelay & 0x7f) << 1) | ((argus.isGas & 0x01) << 8) |
                                 ((argus.isSounder & 0x01) << 9) | ((argus.lastTipo & 0x07) << 10) | ((argus.isMultiSensor & 0x01) << 13) |
                                 ((argus.isCallPoint & 0x01) << 14) | ((argus.isRadio & 0x01) << 15) | ((argus.isFlasher & 0x01) << 16) |
                                 ((argus.preAllTemp & 0xff) << 17) | ((argus.isRepeater & 0x01) << 25);
                buffer = Buffer.alloc(4);
                buffer.writeUInt32LE(valueInt32);
                bufferArray.push(buffer);

                valueInt16 = (argus.prealarm & 0x01) | ((argus.dayNight & 0x03) << 1) | ((argus.sensSecDis & 0x07) << 3) |
                             ((argus.technical & 0x07) << 6) | ((argus.detectorDaySensitivity & 0x03) << 7) | ((argus.detectorNightSensitivity & 0x03) << 9) |
                             ((argus.resSensor & 0x01) << 11) | ((argus.evacuationEnabled & 0x01) << 12) | ((argus.programmed & 0x01) << 13) |
                             ((argus.pNP & 0x01) << 14) | ((argus.secDisEn & 0x01) << 15);
                buffer = Buffer.alloc(2);
                buffer.writeUInt16LE(valueInt16);
                bufferArray.push(buffer);

                buffer = Buffer.alloc(1);
                buffer.writeUInt8(argus.intendedDisChannel);
                bufferArray.push(buffer);

                buffer = Buffer.alloc(1);
                buffer.writeUInt8(argus.zone);
                bufferArray.push(buffer);
                buffer = Buffer.alloc(3); // Reserved bytes
                bufferArray.push(buffer);

                let valueByte = ((argus.technicalFault & 0x01) << 2) | ((argus.technicalRestore & 0x01) << 3);
                buffer = Buffer.alloc(1);
                buffer.writeUInt8(valueByte);
                bufferArray.push(buffer);

                bufferArray.push(Buffer.from([argus.detectorMultiSenseWeekCalendar, argus.detectorSensitivityWeekCalendar]));

                valueByte = (argus.tone & 0x1f) | ((argus.volume & 0x07) << 5);
                buffer = Buffer.alloc(1);
                buffer.writeUInt8(valueByte);
                bufferArray.push(buffer);
            }
        }

        // VTNet serialization
        for (let i = 0; i < VT5000_CONFIG.MAX_VT_NET; i++) {
            const vtnet = this.VT5000_CONFIG.VTNet[i];
            valueInt32 = (vtnet.panelType & 0x03) | ((vtnet.loopNb & 0x1f) << 2);
            buffer = Buffer.alloc(4);
            buffer.writeUInt32LE(valueInt32);
            bufferArray.push(buffer);
        }

        buffer = Buffer.alloc(20); // Zero-filled buffer of 20 bytes
        bufferArray.push(buffer);

        // Combine all buffers into a single buffer
        const finalBuffer = Buffer.concat(bufferArray);
        
        // Return the buffer to be used in the browser for download
        return finalBuffer;
    }
}

export default ConfigFileGenerator;
