import React, { useState } from "react";
import "../Styles/PanelType.css";
import MainFrame from "./ConfigurationFrames/MainFrame";
import LoginPasswordFrame from "./ConfigurationFrames/LoginPasswordFrame";
import ProgrammingFrame from "./ConfigurationFrames/ProgrammingFrame";
import AssistanceFrame from "./ConfigurationFrames/AssistanceFrame";
import ConfigureFrame from "./ConfigurationFrames/ConfigureFrame";
import SystemFrame from "./ConfigurationFrames/SystemFrame";
import DeviceZoneFrame from "./ConfigurationFrames/DeviceZoneFrame";
import ClockFrame from "./ConfigurationFrames/ClockFrame";
import GeneralFrame from "./ConfigurationFrames/GeneralFrame";
import AdditionalCardsFrame from "./ConfigurationFrames/AdditionalCardsFrame";
import DetectorsFrame from "./ConfigurationFrames/DetectorsFrame";
import ModulesFrame from "./ConfigurationFrames/ModulesFrame";
import ZonesFrame from "./ConfigurationFrames/ZonesFrame";
import LogicFunctionsFrame from "./ConfigurationFrames/LogicFunctionsFrame";
import OutputZonesFrame from "./ConfigurationFrames/OutputZonesFrame";
import LanguageFrame from "./ConfigurationFrames/LanguageFrame";
import LoopTypeFrame from "./ConfigurationFrames/LoopTypeFrame";
import VariousFrame from "./ConfigurationFrames/VariousFrame";
import SupervisionFrame from "./ConfigurationFrames/SupervisionFrame";
import BuzzerLedFrame from "./ConfigurationFrames/BuzzerLedFrame";
import PanelInfoFrame from "./ConfigurationFrames/PanelInfoFrame";
import LoopExpansionFrame from "./ConfigurationFrames/LoopExpansionFrame";
import VTNETFrame from "./ConfigurationFrames/VTNETFrame";
import ValuesFactoryFrame from "./ConfigurationFrames/ValuesFactoryFrame";
import PasswordsFrame from "./ConfigurationFrames/PasswordsFrame";
import ModifyPasswordFrame from "./ConfigurationFrames/ModifyPasswordFrame";
import ConfirmPasswordFrame from "./ConfigurationFrames/ConfirmPasswordFrame";
import SummertimeFrame from "./ConfigurationFrames/SummertimeFrame";
import ManualSummertimeFrame from "./ConfigurationFrames/ManualSummertimeFrame";
import HolidaysFrame from "./ConfigurationFrames/HolidaysFrame";
import TimeSlotFrame from "./ConfigurationFrames/TimeSlotFrame";
import WeeklyTrendsFrame from "./ConfigurationFrames/WeeklyTrendsFrame";
import DevicesContent from "./DevicesContent";
import AddWeeklyTrendsFrame from "./ConfigurationFrames/AddWeeklyTrendsFrame";
import ModifyWeeklyTrendsFrame from "./ConfigurationFrames/ModifyWeeklyTrends";
import DeleteWeeklyTrendsFrame from "./ConfigurationFrames/DeleteWeeklyTrendsFrame";
import UpdateAddWeeklyTrendsFrame from "./ConfigurationFrames/UpdateAddWeeklyTrendsFrame";
import UpdateModifyWeeklyTrendsFrame from "./ConfigurationFrames/UpdateModifyWeeklyTrendsFrame";
import AddTimeSlotFrame from "./ConfigurationFrames/AddTimeSlotFrame";
import ModifyTimeSlotFrame from "./ConfigurationFrames/ModifyTimeSlotFrame";
import DeleteTimeSlotFrame from "./ConfigurationFrames/DeleteTimeSlotFrame";
import UpdateAddTimeSlotFrame from "./ConfigurationFrames/UpdateAddTimeSlotFrame";
import UpdateModifyTimeSlotFrame from "./ConfigurationFrames/UpdateModifyTimeSlotFrame";
import UpdateModifyWeeklyTrendsShowTimeSlotFrame from "./ConfigurationFrames/UpdateModifyWeeklyTrendsShowTimeSlotFrame";
import UpdateAddWeeklyTrendsShowTimeSlotFrame from "./ConfigurationFrames/UpdateAddWeeklyTrendsShowTimeSlotFrame";

const ConfigurationContent = ({ panel, setSelectedSubNavItem }) => {
  const [currentFrame, setCurrentFrame] = useState("main");
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectedWeeklyTrendIndex, setSelectedWeeklyTrendIndex] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isFromShowTimeSlot, setIsFromShowTimeSlot] = useState(false);
  const [tempTimeSlotsIndex, setTempTimeSlotsIndex] = useState(Array(8).fill(0));

  const handleFrameChange = (newFrame) => {
    if ((newFrame === "updateModifyWeeklyTrends" && currentFrame !== "updateModifyWeeklyTrendsShowTimeSlot") || (newFrame === "updateAddWeeklyTrends" && currentFrame !== "updateAddWeeklyTrendsShowTimeSlot")) {
      setIsFromShowTimeSlot(false); // Reset if NOT coming from showTimeSlot
      setSelectedDay(null);
    }
    setCurrentFrame(newFrame);
  };

  const frameComponents = {
    main: <MainFrame setCurrentFrame={setCurrentFrame} />,
    loginpassword: <LoginPasswordFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    programming: <ProgrammingFrame setCurrentFrame={setCurrentFrame} />,
    assistance: <AssistanceFrame setCurrentFrame={setCurrentFrame} />,
    configure: <ConfigureFrame setCurrentFrame={setCurrentFrame} />,
    system: <SystemFrame setCurrentFrame={setCurrentFrame} />,
    deviceZone: <DeviceZoneFrame setCurrentFrame={setCurrentFrame} />,
    clock: <ClockFrame setCurrentFrame={setCurrentFrame} />,
    general: <GeneralFrame setCurrentFrame={setCurrentFrame} />,
    additionalCards: <AdditionalCardsFrame setCurrentFrame={setCurrentFrame} />,
    detectors: <DetectorsFrame setCurrentFrame={setCurrentFrame} setSelectedSubNavItem={setSelectedSubNavItem} />,
    modules: <ModulesFrame setCurrentFrame={setCurrentFrame} setSelectedSubNavItem={setSelectedSubNavItem} />,
    zones: <ZonesFrame setCurrentFrame={setCurrentFrame} />,
    logicFunctions: <LogicFunctionsFrame setCurrentFrame={setCurrentFrame} setSelectedSubNavItem={setSelectedSubNavItem} />,
    outputZones: <OutputZonesFrame setCurrentFrame={setCurrentFrame} setSelectedSubNavItem={setSelectedSubNavItem} />,
    language: <LanguageFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    loopType: <LoopTypeFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    various: <VariousFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    supervision: <SupervisionFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    buzzerLed: <BuzzerLedFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    panelInfo: <PanelInfoFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    loopExpansion: <LoopExpansionFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    vtNET: <VTNETFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    valuesFactory: <ValuesFactoryFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    passwords: <PasswordsFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    modifyPassword: <ModifyPasswordFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    confirmPassword: <ConfirmPasswordFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    summertime: <SummertimeFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    manualSummertime: <ManualSummertimeFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    holidays: <HolidaysFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    timeSlot: <TimeSlotFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    weeklyTrends: <WeeklyTrendsFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    devices: <DevicesContent panel={panel} />,
    addWeeklyTrends: <AddWeeklyTrendsFrame setCurrentFrame={handleFrameChange} setSelectedWeeklyTrendIndex={setSelectedWeeklyTrendIndex} panel={panel} />,
    modifyWeeklyTrends: <ModifyWeeklyTrendsFrame setCurrentFrame={handleFrameChange} setSelectedWeeklyTrendIndex={setSelectedWeeklyTrendIndex} panel={panel} />,
    deleteWeeklyTrends: <DeleteWeeklyTrendsFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    updateAddWeeklyTrends: <UpdateAddWeeklyTrendsFrame setCurrentFrame={handleFrameChange}
      panel={panel}
      selectedWeeklyTrendIndex={selectedWeeklyTrendIndex}
      selectedTimeSlotIndex={selectedTimeSlotIndex}
      setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      isFromShowTimeSlot={isFromShowTimeSlot}
      tempTimeSlotsIndex={tempTimeSlotsIndex}
      setTempTimeSlotsIndex={setTempTimeSlotsIndex}
    />,
    updateModifyWeeklyTrends: <UpdateModifyWeeklyTrendsFrame
      setCurrentFrame={handleFrameChange}
      panel={panel}
      selectedWeeklyTrendIndex={selectedWeeklyTrendIndex}
      selectedTimeSlotIndex={selectedTimeSlotIndex}
      setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      isFromShowTimeSlot={isFromShowTimeSlot}
      tempTimeSlotsIndex={tempTimeSlotsIndex}
      setTempTimeSlotsIndex={setTempTimeSlotsIndex}
    />,
    addTimeSlot: <AddTimeSlotFrame setCurrentFrame={setCurrentFrame} setSelectedTimeSlotIndex={setSelectedTimeSlotIndex} panel={panel} />,
    modifyTimeSlot: <ModifyTimeSlotFrame setCurrentFrame={setCurrentFrame} setSelectedTimeSlotIndex={setSelectedTimeSlotIndex} panel={panel} />,
    deleteTimeSlot: <DeleteTimeSlotFrame setCurrentFrame={setCurrentFrame} panel={panel} />,
    updateAddTimeSlot: <UpdateAddTimeSlotFrame setCurrentFrame={setCurrentFrame} panel={panel} selectedTimeSlotIndex={selectedTimeSlotIndex} />,
    updateModifyTimeSlot: <UpdateModifyTimeSlotFrame setCurrentFrame={setCurrentFrame} panel={panel} selectedTimeSlotIndex={selectedTimeSlotIndex} />,
    updateModifyWeeklyTrendsShowTimeSlot: <UpdateModifyWeeklyTrendsShowTimeSlotFrame
      setCurrentFrame={handleFrameChange}
      panel={panel}
      selectedTimeSlotIndex={selectedTimeSlotIndex}
      setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
      selectedDay={selectedDay}
      setIsFromShowTimeSlot={setIsFromShowTimeSlot}
      tempTimeSlotsIndex={tempTimeSlotsIndex}
      setTempTimeSlotsIndex={setTempTimeSlotsIndex}
    />,
    updateAddWeeklyTrendsShowTimeSlot: <UpdateAddWeeklyTrendsShowTimeSlotFrame
      setCurrentFrame={handleFrameChange}
      panel={panel}
      selectedTimeSlotIndex={selectedTimeSlotIndex}
      setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
      selectedDay={selectedDay}
      setIsFromShowTimeSlot={setIsFromShowTimeSlot}
      tempTimeSlotsIndex={tempTimeSlotsIndex}
      setTempTimeSlotsIndex={setTempTimeSlotsIndex}
    />,
  };

  return (
    <div className="frame-container">
      <div
        className="frame"
        style={{
          width: "960px",
          height: "540px",
          border: "1px solid black",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {frameComponents[currentFrame]}
      </div>
    </div>
  );
};

export default ConfigurationContent;
