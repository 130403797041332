import React, { useState, useEffect } from "react";
import UpIcon from "../../Assets/icons/Up.svg";
import DownIcon from "../../Assets/icons/Down.svg";
import Axios from "axios";

const ManualSummertimeFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [startDay, setStartDay] = useState(panel?.VT5000_CONFIG?.DaylightSaving?.savingTime?.startDay || 0);
    const [startMonth, setStartMonth] = useState(panel?.VT5000_CONFIG?.DaylightSaving?.savingTime?.startMonth || 0);
    const [endDay, setEndDay] = useState(panel?.VT5000_CONFIG?.DaylightSaving?.savingTime?.endDay || 0);
    const [endMonth, setEndMonth] = useState(panel?.VT5000_CONFIG?.DaylightSaving?.savingTime?.endMonth || 0);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const incrementValue = (setter, min, max) => {
        setter(prev => (prev >= max ? min : prev + 1));
    };
    
    const decrementValue = (setter, min, max) => {
        setter(prev => (prev <= min ? max : prev - 1));
    };

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.DaylightSaving?.savingTime && panel.VT5000_CONFIG.GeneralParameters) {
            panel.VT5000_CONFIG.DaylightSaving.savingTime.startDay = startDay;
            panel.VT5000_CONFIG.DaylightSaving.savingTime.startMonth = startMonth;
            panel.VT5000_CONFIG.DaylightSaving.savingTime.endDay = endDay;
            panel.VT5000_CONFIG.DaylightSaving.savingTime.endMonth = endMonth;
            panel.VT5000_CONFIG.DaylightSaving.mode = 1;
            panel.VT5000_CONFIG.DaylightSaving.notProgrammed = 0;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_daylightsaving`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                daylightSaving: panel.VT5000_CONFIG.DaylightSaving
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Daylight saving updated successfully");
                        setCurrentFrame("summertime");
                    } else {
                        console.error("Failed to update daylight saving");
                    }
                })
                .catch((error) => {
                    console.error("Error updating daylight saving:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Manual Summertime</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%", marginTop: "5%" }}>
                {[ [[startDay, setStartDay, 1, 31], [startMonth, setStartMonth, 1, 12]], [[endDay, setEndDay, 1, 31], [endMonth, setEndMonth, 1, 12]] ].map((group, groupIndex) => (
                    <div key={groupIndex} style={{ display: "flex", gap: "0px" }}>
                        {group.map(([value, setter, min, max], index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", border: "none", borderRadius: "5px" }}>
                                <button onClick={() => incrementValue(setter, min, max)} style={{ width: "100px", height: "100px", fontSize: "30px", background: "none", borderRadius: "5px" }}>
                                    <img src={UpIcon} alt="Up" style={{ width: "40px", height: "40px" }} />
                                </button>
                                <input type="number" value={value} readOnly style={{ fontSize: "40px", textAlign: "center", width: "100px", height: "50px", background: "none", borderRadius: "5px" }} />
                                <button onClick={() => decrementValue(setter, min, max)} style={{ width: "100px", height: "100px", fontSize: "30px", background: "none", borderRadius: "5px" }}>
                                    <img src={DownIcon} alt="Down" style={{ width: "40px", height: "40px" }} />
                                </button>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("clock")}>Back</button>
            </div>
        </div>
    );
};

export default ManualSummertimeFrame;
