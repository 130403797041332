import React, { useEffect, useState } from 'react';
import "../Styles/LandingPage.css";
import { useNavigate } from 'react-router-dom';
import Img from "../Assets/icons/VELOXLogo.png";
import { toast, ToastContainer } from 'react-toastify';

const LandingPage = () => {
  const navigate = useNavigate();
  const [webVersion, setWebVersion] = useState("");

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/version.json`)
      .then((res) => res.json())
      .then((data) => {
        setWebVersion(data.webVersion);
      });

    const registerSuccessMessage = localStorage.getItem('registerSuccessMessage');
  
    if (registerSuccessMessage) {
      toast.success(registerSuccessMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.removeItem('registerSuccessMessage');  // Remove the message from localStorage after showing it
    }
  }, []);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/register');
  };

  return (
    <div >
      <div className='logo-VELOX'>
        <img src={Img} alt="VELOX Life Safety Inc." className="logo" data-version={webVersion} />
        <button className='login-botton' onClick={handleLoginClick}>LOGIN</button>
        <button className='signup-botton' onClick={handleSignUpClick}>SIGN UP</button>
      </div>
      <hr className='login-breakline' />
      <ToastContainer />
    </div>
  );
};

export default LandingPage;
