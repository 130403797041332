import React, { useState, useEffect } from "react";
import "../../Styles/AddTimeSlotFrame.css";
import Axios from "axios";

const DeleteTimeSlotFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleDelete = (index) => {
        if (panel?.VT5000_CONFIG?.TimeIntervals && panel.VT5000_CONFIG.TimeIntervals.length === 255 &&
            panel.VT5000_CONFIG.TimeIntervals[index].fo && panel.VT5000_CONFIG.TimeIntervals[index].fo.length === 2) {
            panel.VT5000_CONFIG.TimeIntervals[index].fo[0].minuteStart = 0;
            panel.VT5000_CONFIG.TimeIntervals[index].fo[0].minuteEnd = 0;
            panel.VT5000_CONFIG.TimeIntervals[index].fo[0].hourStart = 0;
            panel.VT5000_CONFIG.TimeIntervals[index].fo[0].hourEnd = 0;
            panel.VT5000_CONFIG.TimeIntervals[index].fo[0].programmed = 0;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_timeIntervals`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                timeIntervals: panel.VT5000_CONFIG.TimeIntervals
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Delete Time slot successfully");
                    } else {
                        console.error("Failed to delete time slot");
                    }
                })
                .catch((error) => {
                    console.error("Error deleting time slot:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Delete Time Slot</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0 0 0" }} />
            <div className="timeSlot-buttons-container">
                <div className="timeSlot-buttons-frame">
                    {panel.VT5000_CONFIG.TimeIntervals.map((slot, index) => (
                        <button
                            key={index}
                            className={`timeSlot-button ${slot.fo[0].programmed === 1 ? "programmed" : "not-programmed"}`}
                            disabled={slot.fo[0].programmed !== 1}
                            onClick={() => handleDelete(index)}
                        >
                            <span>{index + 1}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("timeSlot")}>Back</button>
            </div>
        </div>
    );
};

export default DeleteTimeSlotFrame;
