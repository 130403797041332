import React, { useState, useEffect } from "react";
import Axios from "axios";

const SummertimeFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [selectedCheckbox, setSelectedCheckbox] = useState(() => {
        if (panel?.VT5000_CONFIG?.DaylightSaving?.mode === 0) {
            if (panel?.VT5000_CONFIG?.DaylightSaving?.notProgrammed === 0) {
                return "Automatic";
            } else {
                return "Not Managed";
            }
        } else {
            return "Manual";
        }
    });

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleCheckboxChange = (index) => {
        setSelectedCheckbox(index === selectedCheckbox ? null : index);
        if (index === "Manual") {
            setCurrentFrame("manualSummertime");
        }
    };

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.DaylightSaving && panel.VT5000_CONFIG.GeneralParameters) {
            if (selectedCheckbox === "Automatic") {
                panel.VT5000_CONFIG.DaylightSaving.mode = 0;
                panel.VT5000_CONFIG.DaylightSaving.notProgrammed = 0;
            } else if (selectedCheckbox === "Not Managed") {
                panel.VT5000_CONFIG.DaylightSaving.mode = 0;
                panel.VT5000_CONFIG.DaylightSaving.notProgrammed = 1;
            }

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_daylightsaving`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                daylightSaving: panel.VT5000_CONFIG.DaylightSaving
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Daylight saving updated successfully");
                    } else {
                        console.error("Failed to update daylight saving");
                    }
                })
                .catch((error) => {
                    console.error("Error updating daylight saving:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Summertime</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ fontSize: "30px", marginTop: "9%", marginBottom: "2%" }}>Daylight Saving Management</div>
            <div style={{ display: "flex", justifyContent: "center", gap: "5%" }}>
                {["Automatic", "Manual", "Not Managed"].map((num) => (
                    <div key={num} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "300px", height: "80px", border: "2px solid black", borderRadius: "5px", cursor: "pointer", background: selectedCheckbox === num ? "#C00D0D" : "#FFF" }}
                        onClick={() => handleCheckboxChange(num)}>
                        <span style={{ fontSize: "30px", color: selectedCheckbox === num ? "#FFF" : "#000" }}>{num}</span>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("clock")}>Back</button>
            </div>
        </div>
    );
};

export default SummertimeFrame;
