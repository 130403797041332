import React, { useState, useEffect } from "react";
import Axios from "axios";
import defaultPanelData from "../../Assets/defaultPanelData/defaultPanelData.json";
import { toast } from "react-toastify";

const ValuesFactoryFrame = ({ setCurrentFrame, panel }) => {
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const resetToFactoryDefaults = () => {
        const email = localStorage.getItem("userEmail");
        let panelData = { ...defaultPanelData };
        panelData.GeneralParameters.panelName = panel.VT5000_CONFIG.GeneralParameters.panelName;

        Axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset_panel`, {
            email: email,
            panel: panelData,
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success("Panel reset successfully!");
                    setCurrentFrame("system");
                } else {
                    toast.error("Failed to reset panel ");
                }
            })
            .catch(() => {
                toast.error("Error reseting panel!");
            });
    };



    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Factory Reset</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ fontSize: "30px", marginBottom: "10px", marginTop: "9%" }}>Do you want to reset the panel to factory default?</div>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
                <button style={{ width: "120px", height: "120px", fontSize: "30px", background: "#FFF", color: "black", borderRadius: "10px", border: "2px solid #000", cursor: "pointer", transition: "background 0.3s, color 0.3s" }}
                    onClick={resetToFactoryDefaults}
                    onMouseOver={(e) => { e.target.style.background = "#C00D0D"; e.target.style.color = "white"; }}
                    onMouseOut={(e) => { e.target.style.background = "#FFF"; e.target.style.color = "black"; }}>
                    Yes
                </button>
                <button style={{ width: "120px", height: "120px", fontSize: "30px", background: "#FFF", color: "black", borderRadius: "10px", border: "2px solid #000", cursor: "pointer", transition: "background 0.3s, color 0.3s" }}
                    onClick={() => setCurrentFrame("system")}
                    onMouseOver={(e) => { e.target.style.background = "#C00D0D"; e.target.style.color = "white"; }}
                    onMouseOut={(e) => { e.target.style.background = "#FFF"; e.target.style.color = "black"; }}>
                    No
                </button>
            </div>
            <div style={{ fontSize: "30px", marginTop: "10px" }}>Notice the setting will be overwritten</div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("system")}>Back</button>
            </div>
        </div>
    );
};

export default ValuesFactoryFrame;
