import React, { useState, useEffect } from "react";
import UpIcon from "../../Assets/icons/Up.svg";
import DownIcon from "../../Assets/icons/Down.svg";
import Axios from "axios";

const UpdateAddTimeSlotFrame = ({ setCurrentFrame, panel, selectedTimeSlotIndex }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [startHour, setStartHour] = useState(() => {
        if (panel?.VT5000_CONFIG?.TimeIntervals) {
            if (panel.VT5000_CONFIG.TimeIntervals.length === 255 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo.length === 2 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].programmed !== 0) {
                return panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].hourStart;
            }
        }
        return 0;
    });
    const [startMinute, setStartMinute] = useState(() => {
        if (panel?.VT5000_CONFIG?.TimeIntervals) {
            if (panel.VT5000_CONFIG.TimeIntervals.length === 255 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo.length === 2 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].programmed !== 0) {
                return panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].minuteStart;
            }
        }
        return 0;
    });
    const [endHour, setEndHour] = useState(() => {
        if (panel?.VT5000_CONFIG?.TimeIntervals) {
            if (panel.VT5000_CONFIG.TimeIntervals.length === 255 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo.length === 2 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].programmed !== 0) {
                return panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].hourEnd;
            }
        }
        return 0;
    });
    const [endMinute, setEndMInute] = useState(() => {
        if (panel?.VT5000_CONFIG?.TimeIntervals) {
            if (panel.VT5000_CONFIG.TimeIntervals.length === 255 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo.length === 2 && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].programmed !== 0) {
                return panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].minuteEnd;
            }
        }
        return 0;
    });

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const incrementValue = (setter, min, max) => {
        setter(prev => (prev >= max ? min : prev + 1));
    };
    
    const decrementValue = (setter, min, max) => {
        setter(prev => (prev <= min ? max : prev - 1));
    };
    

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.TimeIntervals && panel.VT5000_CONFIG.TimeIntervals.length === 255 &&
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo && panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo.length === 2) {
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].minuteStart = startMinute;
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].minuteEnd = endMinute;
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].hourStart = startHour;
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].hourEnd = endHour;
            panel.VT5000_CONFIG.TimeIntervals[selectedTimeSlotIndex].fo[0].programmed = 1;

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_timeIntervals`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                timeIntervals: panel.VT5000_CONFIG.TimeIntervals
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("Time slot updated successfully");
                        setCurrentFrame("addTimeSlot");
                    } else {
                        console.error("Failed to update time slot");
                    }
                })
                .catch((error) => {
                    console.error("Error updating time slot:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Add Time Slot {selectedTimeSlotIndex + 1}</span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                {[[startHour, setStartHour, 0, 23, "Start Hour:"], [startMinute, setStartMinute, 0, 59, "Start Minute:"]].map(([value, setter, min, max, label], index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "5px" }}>
                        <span style={{ fontSize: "30px" }}>{label}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button onClick={() => incrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={UpIcon} alt="Up" style={{ width: "40px", height: "40px" }} />
                            </button>
                            <input type="number" value={value} readOnly style={{ fontSize: "40px", textAlign: "center", width: "100px", height: "100px", border: "2px solid black", borderRadius: "5px" }} />
                            <button onClick={() => decrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={DownIcon} alt="Down" style={{ width: "40px", height: "40px" }} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%", marginTop: "5%" }}>
                {[[endHour, setEndHour, 0, 23, "End Hour:"], [endMinute, setEndMInute, 0, 59, "End Minute:"]].map(([value, setter, min, max, label], index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "5px" }}>
                        <span style={{ fontSize: "30px" }}>{label}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button onClick={() => incrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={UpIcon} alt="Up" style={{ width: "40px", height: "40px" }} />
                            </button>
                            <input type="number" value={value} readOnly style={{ fontSize: "40px", textAlign: "center", width: "100px", height: "100px", border: "2px solid black", borderRadius: "5px" }} />
                            <button onClick={() => decrementValue(setter, min, max)} style={{ width: "100px", height: "100px", background: "none", borderRadius: "5px" }}>
                                <img src={DownIcon} alt="Down" style={{ width: "40px", height: "40px" }} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("addTimeSlot")}>Back</button>
            </div>
        </div>
    );
};

export default UpdateAddTimeSlotFrame;
