import React, { useState, useEffect } from "react";
import "../Styles/OutputZonesContent.css";
import {
  Dropdown,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
  Panel,
  PanelType,
  Toggle,
  SpinButton
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Axios from "axios";


const OutputZonesContent = ({ panel }) => {
  const GenerateDeviceAddressName = (loop, address) => {
    const addr = address < 10 ? "00" + address.toString() : address < 100 ? "0" + address.toString() : address.toString();
    return `L${loop.toString()}M${addr}`
  }
  const [selectedLoop, setSelectedLoop] = useState(0);
  const [selectedOutputZone, setSelectedOutputZone] = useState(null); // Store selected device index
  const [isDialogOpen, { setTrue: openDialog, setFalse: dismissDialog }] = useBoolean(false);
  const [isSilenceable, setIsSilenceable] = useState(false);
  const [inputValues, setInputValues] = useState(Array(8).fill(""));

  if (!panel || Object.keys(panel).length === 0) {
    return <div>No panel data found.</div>;
  }

  const loopCardNb = panel.VT5000_CONFIG.GeneralParameters?.loopCardNb || 0;
  const loopButtonsCount = 1 + 2 * loopCardNb;

  const handleIsSelected = (loopIndex, deviceIndex) => {
    if (selectedOutputZone === null) {
      return false;
    }
    const devices = panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices;
    if ((loopIndex + 1 === devices[0].loop) && (deviceIndex + 1 === devices[0].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[1].loop) && (deviceIndex + 1 === devices[1].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[2].loop) && (deviceIndex + 1 === devices[2].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[3].loop) && (deviceIndex + 1 === devices[3].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[4].loop) && (deviceIndex + 1 === devices[4].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[5].loop) && (deviceIndex + 1 === devices[5].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[6].loop) && (deviceIndex + 1 === devices[6].address)) {
      return true;
    }
    if ((loopIndex + 1 === devices[7].loop) && (deviceIndex + 1 === devices[7].address)) {
      return true;
    }
  };

  const handleOutputZoneButtonClick = (index) => {
    let res = Array(8).fill("");
    let i = 0;
    const devices = panel.VT5000_CONFIG.OutputZones[index].devices;
    if (devices[0].loop !== 0 && devices[0].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[0].loop, devices[0].address);
      i++;
    }
    if (devices[1].loop !== 0 && devices[1].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[1].loop, devices[1].address);
      i++;
    }
    if (devices[2].loop !== 0 && devices[2].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[2].loop, devices[2].address);
      i++;
    }
    if (devices[3].loop !== 0 && devices[3].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[3].loop, devices[3].address);
      i++;
    }
    if (devices[4].loop !== 0 && devices[4].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[4].loop, devices[4].address);
      i++;
    }
    if (devices[5].loop !== 0 && devices[5].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[5].loop, devices[5].address);
      i++;
    }
    if (devices[6].loop !== 0 && devices[6].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[6].loop, devices[6].address);
      i++;
    }
    if (devices[7].loop !== 0 && devices[7].address !== 0) {
      res[i] = GenerateDeviceAddressName(devices[7].loop, devices[7].address);
      i++;
    }
    setInputValues(res);
    console.log(res);
    setSelectedOutputZone(index);
    openDialog();
  };

  const handleDeviceButtonClick = (loopIndex, deviceIndex) => {
    const deviceName = GenerateDeviceAddressName(loopIndex + 1, deviceIndex + 1);
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      const index = newValues.indexOf(deviceName);
      if (index !== -1) {
        newValues.splice(index, 1);
        while (newValues.length < 8) {
          newValues.push("");
        }
      } else {
        const firstEmptyIndex = newValues.indexOf("");
        if (firstEmptyIndex !== -1) {
          newValues[firstEmptyIndex] = deviceName;
        }
      }
      return newValues;
    });
  };

  const onSave = () => {
    if (selectedOutputZone >= 0) {
      panel.VT5000_CONFIG.OutputZones[selectedOutputZone].programmed = 1;
      panel.VT5000_CONFIG.OutputZones[selectedOutputZone].isSilenceable = isSilenceable ? 1 : 0;
      for (let i = 0; i < inputValues.length; i++) {
        if (inputValues[i] === null || inputValues[i].length < 6) {
          panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].loop = 0;
          panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].address = 0;
        }
        else {
          const loopIndex = parseInt(inputValues[i].slice(1, 2), 10);
          const deviceIndex = parseInt(inputValues[i].slice(3, 6), 10);
          panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].loop = loopIndex;
          panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].address = deviceIndex;
        }
        panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].active = 0;
      }
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_outputZones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      outputZones: panel.VT5000_CONFIG.OutputZones,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("OutputZones updated successfully");
          dismissDialog();
        } else {
          console.error("Failed to update OutputZones");
          
        }
      })
      .catch((error) => {
        console.error("Error updating OutputZones:", error);
      });
  };

  const onRemove = () => {
    if (selectedOutputZone >= 0) {
      panel.VT5000_CONFIG.OutputZones[selectedOutputZone].programmed = 0;
      panel.VT5000_CONFIG.OutputZones[selectedOutputZone].isSilenceable = 0;
      for (let i = 0; i < panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices.length; i++) {
        panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].loop = 0;
        panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].address = 0;
        panel.VT5000_CONFIG.OutputZones[selectedOutputZone].devices[i].active = 0;
      }
    }

    const userEmail = localStorage.getItem('userEmail');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_outputZones`, {
      email: userEmail,
      panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
      outputZones: panel.VT5000_CONFIG.OutputZones,
    })
      .then((response) => {
        if (response.data.success) {
          console.log("OutputZones updated successfully");
          dismissDialog();
        } else {
          console.error("Failed to update OutputZones");
          
        }
      })
      .catch((error) => {
        console.error("Error updating OutputZones:", error);
      });
  };

  return (
    <div className="outputzone-content">
      {/* Output Zones Buttons */}
      <div className="outputzone-buttons-container">
        <div className="outputzone-buttons-frame">
          {Array.from({ length: panel.VT5000_CONFIG.OutputZones.length - 1 }, (_, index) => {
            const i = index; // Output Zone index
            const outputZone = panel.VT5000_CONFIG.OutputZones?.[i];
            const isProgrammed = outputZone?.programmed === 1;

            let buttonClass = isProgrammed ? "programmed" : "not-programmed"; // Default class

            return (
              <button
                key={index}
                className={`outputzone-button ${buttonClass}`}
                onClick={() => handleOutputZoneButtonClick(index)}
              >
                <span>{index + 1}</span>
              </button>
            );
          })}
        </div>
      </div>

      <Dialog
        hidden={!isDialogOpen}
        onDismiss={dismissDialog}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        minWidth={"1000px"}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", height: "32px" }}>
              <span style={{ fontSize: "16px" }}>silenceable:</span>
              <Toggle
                checked={isSilenceable}
                onChange={() => setIsSilenceable(!isSilenceable)}
                onText="Yes"
                offText="No"
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right" }}>
              <div style={{ fontSize: "18px", fontWeight: "bold", alignSelf: "flex-end", marginRight: "200px" }}>Modules</div>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px", marginTop: "10px" }}>
                {inputValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    value={value}
                    readOnly
                    style={{
                      width: "100px",
                      height: "40px",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontSize: "16px",
                      marginRight: "10px"
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="devices-content">
            {/* Loop Buttons */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
              <div className="loop-buttons">
                <span>Loop:</span>
                {Array.from({ length: loopButtonsCount }, (_, index) => (
                  <button
                    key={index}
                    className={`loop-button ${selectedLoop === index ? "selected-loop" : ""}`}
                    onClick={() => setSelectedLoop(index)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <PrimaryButton text="Save" onClick={() => onSave()} />
                <DefaultButton text="Remove" onClick={() => onRemove()} />
                <DefaultButton text="Back" onClick={dismissDialog} />
              </div>
            </div>

            {/* Device Buttons */}
            <div className="device-buttons-container">
              <div className="device-buttons-frame">
                {Array.from({ length: panel.VT5000_CONFIG.ArgusTablePr[0].length }, (_, index) => {
                  const i = selectedLoop; // Loop index
                  const j = index; // Device index
                  const device = panel.VT5000_CONFIG.ArgusTablePr?.[i]?.[j];
                  const isProgrammed = device?.programmed === 1;
                  const isModule = isProgrammed && (device.deviceType === 1);
                  const isDevice = isProgrammed && (device.deviceType === 0);
                  const isSmoke = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 0);
                  const isThermal = isDevice && (device.lastTipo === 1);
                  const isSmokeTherm = isDevice && (device.lastTipo === 0) && (device.isMultiSensor === 1);
                  const isOutputModule = isModule && device.lastTipo === 4;
                  const isSelected = inputValues.includes(GenerateDeviceAddressName(i + 1, j + 1));

                  let buttonClass = "not-programmed"; // Default class
                  // if (isProgrammed) {
                  //   buttonClass = device?.deviceType === 0 ? "sensor" : "input";
                  // }
                  if (isModule) {
                    buttonClass = "module";
                  }
                  else if (isSmoke) {
                    buttonClass = "smoke";
                  }
                  else if (isThermal) {
                    buttonClass = "heat";
                  }
                  else if (isSmokeTherm) {
                    buttonClass = "smokeTherm";
                  }

                  return (
                    <button
                      key={index}
                      className={`device-button ${buttonClass} ${isSelected ? "selected" : ""}`}
                      disabled={!isOutputModule}
                      onClick={() => handleDeviceButtonClick(i, j)}
                    >
                      <span>{index + 1}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OutputZonesContent;
