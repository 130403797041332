import React, { useState, useEffect } from "react";
import PreviousIcon from "../../Assets/icons/Previous.svg";
import NextIcon from "../../Assets/icons/Next.svg";

const MainFrame = ({ setCurrentFrame }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let formattedTime = now.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).replace(/,/g, ""); // Remove comma between date and time
      
      formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Events in Progress</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", position: "absolute", bottom: "0px" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("loginpassword")}>Login</button>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} disabled>
          <img src={PreviousIcon} alt="Previous" style={{ width: "40px", height: "40px" }} />
        </button>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} disabled>
          <img src={NextIcon} alt="Next" style={{ width: "40px", height: "40px" }} />
        </button>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} disabled>Mute Buzzer</button>
      </div>
    </div>
  );
};

export default MainFrame;
