import React, { useState, useEffect } from "react";
import Axios from "axios";

const LanguageFrame = ({ setCurrentFrame, panel }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [language, setLanguage] = useState(panel?.VT5000_CONFIG?.GeneralParameters?.panelLanguage ? panel.VT5000_CONFIG.GeneralParameters.panelLanguage : 0);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let formattedTime = now.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).replace(/,/g, ""); // Remove comma between date and time

      formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleLanguageChange = (value) => {
    console.log(panel);
    if (panel?.VT5000_CONFIG?.GeneralParameters) {
      if (panel.VT5000_CONFIG.GeneralParameters.panelLanguage !== value) {
        panel.VT5000_CONFIG.GeneralParameters.panelLanguage = value;
        setLanguage(value);

        const userEmail = localStorage.getItem('userEmail');
        Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_generalparameters`, {
          email: userEmail,
          panelName: panel.VT5000_CONFIG.GeneralParameters.panelName, // Ensure panelId exists in your panel data
          generalparameters: panel.VT5000_CONFIG.GeneralParameters
        })
          .then((response) => {
            if (response.data.success) {
              console.log("Panel language updated successfully");
            } else {
              console.error("Failed to update panel language");
            }
          })
          .catch((error) => {
            console.error("Error updating panel language:", error);
          });
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ textAlign: "left", flex: 1 }}>Language</span>
        <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
      </div>
      <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px", width: "98%", height: "72%", textAlign: "center" }}>
        {[{ text: "Italiano", value: 0 },
        { text: "English", value: 1 },
        { text: "Türkçe", value: 2 },
        { text: "Français", value: 3 },
        { text: "العربية", value: 4 },
        { text: "فارسی", value: 5 },
        { text: "Polski", value: 6 },
        { text: "Eesti", value: 7 },
        { text: "Român", value: 8 },
        { text: "Português", value: 9 }].map((btn, index) => (
          <button key={index} 
            style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              justifyContent: "center", 
              padding: "10px", 
              fontSize: "24px", 
              width: "100%", 
              background: language === btn.value ? "#0078D4" : "none", 
              color: language === btn.value ? "white" : "black", 
              borderRadius: "5px", 
              cursor: "pointer", 
              border: language === btn.value ? "2px solid #005A9E" : "1px solid black"
            }}
            onClick={() => handleLanguageChange(btn.value)}
          >
            {btn.text}
          </button>
        ))}
      </div>
      <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
        <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 40% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
          <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("general")}>Back</button>
        </div>
      </div>
    );
};

export default LanguageFrame;
