import React, { useState, useEffect } from "react";
import Axios from "axios";

const UpdateModifyWeeklyTrendsFrame = ({ setCurrentFrame, setSelectedTimeSlotIndex, selectedTimeSlotIndex, setSelectedDay, selectedDay, panel, selectedWeeklyTrendIndex, isFromShowTimeSlot, tempTimeSlotsIndex, setTempTimeSlotsIndex }) => {
    const [currentTime, setCurrentTime] = useState("");
    const [timeSlotIndexes, setTimeSlotIndexes] = useState(isFromShowTimeSlot ? tempTimeSlotsIndex : panel.VT5000_CONFIG.WeeklyTrends[selectedWeeklyTrendIndex].days);

    const convertTimeRange = (index) => {
        if (!panel || !panel.VT5000_CONFIG || !panel.VT5000_CONFIG.TimeIntervals) return "";

        const timeInterval = panel.VT5000_CONFIG.TimeIntervals[index - 1]?.fo?.[0];
        if (!timeInterval) return "";

        const startHour = timeInterval.hourStart;
        const startMinute = timeInterval.minuteStart;
        const endHour = timeInterval.hourEnd;
        const endMinute = timeInterval.minuteEnd;

        const formatTime = (num) => (num < 10 ? "0" + num.toString() : num.toString());

        return `${formatTime(startHour)}:${formatTime(startMinute)} - ${formatTime(endHour)}:${formatTime(endMinute)}`;
    };

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"];
    const [timeSlots, setTimeSlots] = useState(timeSlotIndexes.map((index) => convertTimeRange(index)));

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let formattedTime = now.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            }).replace(/,/g, ""); // Remove comma between date and time

            formattedTime = formattedTime.replace(/(\d{4})\//, "$1 "); // Remove '/' after year
            setCurrentTime(formattedTime);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleIndexClick = (day, index) => {
        setSelectedDay(day);
        setSelectedTimeSlotIndex(index);
        setTempTimeSlotsIndex(timeSlotIndexes);
        setCurrentFrame("updateModifyWeeklyTrendsShowTimeSlot"); // Navigate to ShowTimeSlotFrame
    };

    const handleSave = () => {
        if (panel?.VT5000_CONFIG?.WeeklyTrends && selectedWeeklyTrendIndex >= 0 && timeSlotIndexes !== null) {
            panel.VT5000_CONFIG.WeeklyTrends[selectedWeeklyTrendIndex].programmed = 1;
            for (let i = 0; i < timeSlotIndexes.length; i++) {
                panel.VT5000_CONFIG.WeeklyTrends[selectedWeeklyTrendIndex].days[i] = timeSlotIndexes[i];
            }

            const userEmail = localStorage.getItem('userEmail');
            Axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_panel_weeklyTrends`, {
                email: userEmail,
                panelName: panel.VT5000_CONFIG.GeneralParameters.panelName,
                weeklyTrends: panel.VT5000_CONFIG.WeeklyTrends
            })
                .then((response) => {
                    if (response.data.success) {
                        console.log("WeeklyTrends saving updated successfully");
                        setCurrentFrame("modifyWeeklyTrends");
                    } else {
                        console.error("Failed to update WeeklyTrends");
                    }
                })
                .catch((error) => {
                    console.error("Error updating WeeklyTrends:", error);
                });
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ fontSize: 40, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ textAlign: "left", flex: 1 }}>Weekly Trend </span>
                <span style={{ textAlign: "right", flex: 1 }}>{currentTime}</span>
            </div>
            <hr style={{ width: "100%", border: "1px solid black", margin: "5px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "90%", margin: "20px 0" }}>
                {/* Left Column: Monday - Thursday */}
                <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    {days.slice(0, 4).map((label, i) => (
                        <div key={i} style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                            {/* Day Label */}
                            <span style={{ fontSize: "24px", width: "120px", textAlign: "left" }}>{label}</span>

                            {/* Index Button */}
                            <button 
                                style={{ fontSize: "24px", padding: "10px", background: "#E0E0E0", borderRadius: "5px", border: "1px solid black", height: "60px", width: "80px", textAlign: "center" }}
                                onClick={() => handleIndexClick(label, timeSlotIndexes[i])}
                            >
                                {timeSlotIndexes[i]}
                            </button>

                            {/* Time Display (Disabled Button) */}
                            <button 
                                style={{ fontSize: "24px", padding: "10px", background: "#FFFFFF", borderRadius: "5px", border: "1px solid black", cursor: "not-allowed", height: "60px", width: "200px", textAlign: "center" }} 
                                disabled
                            >
                                {timeSlots[i] || ""}
                            </button>
                        </div>
                    ))}
                </div>

                {/* Right Column: Friday - Sunday + Holiday */}
                <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    {days.slice(4, 8).map((label, i) => (
                        <div key={i + 4} style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%" }}>
                            {/* Day Label */}
                            <span style={{ fontSize: "24px", width: "120px", textAlign: "left" }}>{label}</span>

                            {/* Index Button */}
                            <button 
                                style={{ fontSize: "24px", padding: "10px", background: "#E0E0E0", borderRadius: "5px", border: "1px solid black", height: "60px", width: "80px", textAlign: "center" }}
                                onClick={() => handleIndexClick(label, timeSlotIndexes[i + 4])}
                            >
                                {timeSlotIndexes[i + 4]}
                            </button>

                            {/* Time Display (Disabled Button) */}
                            <button 
                                style={{ fontSize: "24px", padding: "10px", background: "#FFFFFF", borderRadius: "5px", border: "1px solid black", cursor: "not-allowed", height: "60px", width: "200px", textAlign: "center" }} 
                                disabled
                            >
                                {timeSlots[i + 4] || ""}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: "flex", width: "98%", justifyContent: "space-evenly", position: "absolute", bottom: "2%" }}>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 10% 0 0", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("main")}>Logout</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 1px 0 0", background: "none", borderRadius: "5px" }} onClick={handleSave}>Save</button>
                <button style={{ flex: 1, padding: "0px", fontSize: "40px", margin: "0 0px", background: "none", borderRadius: "5px" }} onClick={() => setCurrentFrame("modifyWeeklyTrends")}>Back</button>
            </div>
        </div>
    );
};

export default UpdateModifyWeeklyTrendsFrame;
